export default {
  methods: {
    highlightSearchResult (text, query, matchBeginningOfWord) {
      const regexSafeQuery = query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
      const prefixMatcher = (matchBeginningOfWord) ? '(^| )' : '()'
      const regexString = prefixMatcher + '(' + regexSafeQuery.replace(/ +/g, '|') + ')'
      const regexMatcher = new RegExp(regexString, 'gi')

      text = String(text).replace(regexMatcher, '$1<span class="highlighted-text">$2</span>')

      return text
    },
  },
}
