<script setup>
import VDefinition from '@/components/VDefinition.vue'
import { useLoadingDocksForLocation } from '@/composables/useLoadingDocksForLocation.js'

defineProps({
  selectedLoadingDock: {
    type:     String,
    required: false,
    default:  null,
  },
})
const emit = defineEmits(['update:selectedLoadingDock'])

const { loadingDocks } = useLoadingDocksForLocation()
</script>

<template>
  <VDefinition
    label="Lastkaj"
  >
    <select
      :value="selectedLoadingDock || -1"
      class="form-select"
      data-test="loading-dock-select"
      @change="emit('update:selectedLoadingDock', $event.target.value)"
    >
      <option
        value="-1"
      >
        Alla
      </option>
      <option
        v-for="loadingDock in loadingDocks"
        :key="loadingDock.id"
        :value="loadingDock.id"
      >
        {{ loadingDock.name }}
      </option>
    </select>
  </VDefinition>
</template>
