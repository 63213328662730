import { watch, computed } from 'vue'
import { useFetch } from '@/composables/useFetch'
import { useStore } from 'vuex'

export function useLoadingDocksForLocation () {
  const store = useStore()

  const locationId = computed(() => {
    return store.state.settings.current_user.location.id
  })

  const { data: loadingDocks, fetch: fetchLoadingDocks } = useFetch(
    '/internal_api/loading_docks',
    {},
    { immediate: false, root: ['loading_docks'] },
  )

  const fetchLoadingDocksForLocation = () => {
    fetchLoadingDocks({ params: { location: locationId.value } })
  }

  watch(locationId, () => {
    fetchLoadingDocksForLocation()
  })

  fetchLoadingDocksForLocation()

  return { loadingDocks }
}
