<script setup>
import VDefinition from '@/components/VDefinition.vue'
import { useRegionsForLocation } from '@/composables/useRegionsForLocation.js'

defineProps({
  selectedRegion: {
    type:     String,
    required: false,
    default:  null,
  },
})
const emit = defineEmits(['update:selectedRegion'])

const { regions } = useRegionsForLocation()
</script>

<template>
  <VDefinition
    label="Region"
  >
    <select
      :value="selectedRegion || -1"
      class="form-select"
      data-test="region-select"
      @change="emit('update:selectedRegion', $event.target.value)"
    >
      <option
        value="-1"
      >
        Alla
      </option>
      <option
        v-for="region in regions"
        :key="region.id"
        :value="region.id"
      >
        {{ region.name }}
      </option>
    </select>
  </VDefinition>
</template>
