<template>
  <div>
    <Loading
      :active="settingsLoading"
      :is-full-page="true"
    />
    <div
      v-if="settingsFetched"
      class="d-flex flex-column min-vh-100 mb-5"
    >
      <AppNavbar />
      <main>
        <RouterView />
      </main>
      <FeedbackForm />
    </div>
    <FlashMessage :position="'right bottom'" />
  </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue'
import Loading from 'vue-loading-overlay'
import FeedbackForm from '@/components/FeedbackForm.vue'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  components: {
    AppNavbar,
    Loading,
    FeedbackForm,
  },
  inject: ['axios'],
  data () {
    return {
      settingsLoading: false,
      settingsFetched: false,
    }
  },
  created () {
    this.getSettings()
  },
  methods: {
    getSettings () {
      this.settingsLoading = true
      this.axios.get('/internal_api/settings')
        .then((response) => {
          this.$store.commit('setSettings', response.data)
          this.settingsFetched = true
        }).catch(() => {
          this.$flashMessage.show({
            type: 'error',
            text: 'Misslyckades att ladda inställningar. Testa att ladda om sidan.',
            time: 0,
          })
        }).then(() => {
          this.settingsLoading = false
        })
    },
  },
}
</script>

<style scoped lang="scss">
main {
  margin-top: 60px;
}
</style>
