<template>
  <div class="col-6 offset-3 mb-3 bg-white rounded-3 border shadow-sm overflow-hidden">
    <div class="container p-0 mt-3">
      <header data-test="sales-order-exporter-header">
        <div
          v-if="done"
          class="d-flex align-items-center"
        >
          <div class="large-badge done">
            <i class="bi bi-check2-all" />
          </div>
          Ordern är {{ $t(`salesOrder.state.${orderState}`).toLowerCase() }}
        </div>
        <div
          v-else-if="closed"
          class="d-flex align-items-center"
        >
          <div class="large-badge stop">
            <i class="bi bi-check2-all" />
          </div>
          Ordern är {{ $t(`salesOrder.state.${orderState}`).toLowerCase() }}
        </div>
        <div
          v-else-if="validForApprove"
          class="d-flex align-items-center"
        >
          <div class="large-badge ready">
            <i class="bi bi-check-lg" />
          </div>
          Ordern är redo för att godkännas
        </div>
        <div
          v-else
          class="d-flex align-items-center"
        >
          <div class="large-badge stop">
            <i class="bi bi-x-lg" />
          </div>
          Ordern kan inte godkännas
        </div>
      </header>

      <div class="row">
        <ValidationSummaryDisplay :validation-summary="validationSummary" />
      </div>

      <div
        v-if="!validForApprove && !done && netsuiteEnabled"
        class="my-3 fs-13 fst-italic"
      >
        Du kan ändå exportera ordern till Netsuite och fortsätta orderberedningen där.
      </div>

      <div class="row p-2 py-3 bg-tw-slate-50 d-flex justify-content-between flex-row-reverse">
        <LoadingButton
          v-if="!done"
          :loading="approving"
          :disabled="!startable || !validForApprove"
          class="btn btn-success shadow-sm"
          data-test="approve-button"
          @click="sendApprove"
        >
          <template #perform>
            Godkänn
          </template>
          <template #loading>
            Godkänner...
          </template>
        </LoadingButton>

        <button
          v-if="done && netsuiteEnabled"
          class="btn btn-secondary shadow-sm me-2"
          data-test="open-in-netsuite-button"
          @click="openNetsuiteLink"
        >
          Öppna i Netsuite
        </button>

        <LoadingButton
          v-if="!done && netsuiteEnabled"
          :loading="exporting"
          :disabled="!startable"
          class="btn btn-secondary shadow-sm me-2"
          data-test="export-button"
          @click="sendExport"
        >
          <template #perform>
            Exportera till Netsuite
          </template>
          <template #loading>
            Exporterar...
          </template>
        </LoadingButton>

        <SalesOrderCloser
          v-if="!done"
          :order-id="salesOrderId"
          :disabled="approving || exporting || closed"
          @close-completed="$emit('update-state', { state: 'closed' })"
        />
      </div>
    </div>
  </div>
  <SalesOrderExportErrorDisplay
    v-if="failed && exportError"
    :export-error="exportError"
  />
</template>

<script>
import LoadingButton from '@/components/LoadingButton.vue'
import ValidationSummaryDisplay from '@/components/ValidationSummaryDisplay.vue'
import SalesOrderExportErrorDisplay from '@/components/SalesOrderExportErrorDisplay.vue'
import SalesOrderCloser from '@/components/SalesOrderCloser.vue'
import performanceTimer from '@/composables/performanceTimer'

export default {
  components: {
    LoadingButton,
    ValidationSummaryDisplay,
    SalesOrderExportErrorDisplay,
    SalesOrderCloser,
  },
  inject: ['axios'],
  props:  {
    exportError: {
      type:     Object,
      required: false,
      default:  () => {},
    },
    orderState: {
      type:     String,
      required: true,
    },
    orderSource: {
      type:     String,
      required: true,
    },
    netsuiteInternalId: {
      type:     Number,
      required: false,
      default:  null,
    },
    salesOrderId: {
      type:     Number,
      required: true,
    },
    validationSummary: {
      type:     Object,
      required: true,
    },
  },
  emits: ['update-state'],
  data () {
    return {
      showApproveModal: false,
    }
  },
  computed: {
    startable () {
      return ['new', 'export_failed', 'approve_failed'].includes(this.orderState)
    },
    done () {
      return ['exported', 'approved'].includes(this.orderState)
    },
    closed () {
      return this.orderState === 'closed'
    },
    failed () {
      return ['export_failed', 'approve_failed'].includes(this.orderState)
    },
    exporting () {
      return this.orderState === 'exporting'
    },
    approving () {
      return this.orderState === 'approving'
    },
    validForApprove () {
      return this.validationSummary.approvable
    },
    manual () {
      return this.orderSource === 'manual'
    },
    netsuiteEnabled () {
      return this.$store.state.settings.ns_root_href
    },
  },
  methods: {
    sendExport () {
      this.send('start_netsuite_export', 'exporting')
    },
    sendApprove () {
      this.send('approve', 'approving')
    },
    send (action, newState) {
      const originalState = this.orderState
      this.$emit('update-state', { state: newState })
      this.axios.post(`/internal_api/sales_orders/${this.salesOrderId}/${action}`)
        .then((response) => {
          const data = response.data.data

          this.$emit(
            'update-state',
            {
              state:                data.state,
              netsuite_internal_id: data.netsuite_internal_id,
              approved_by:          data.approved_by,
              approved_at:          data.approved_at,
            },
          )
        }).catch(() => {
          this.$emit('update-state', { state: originalState })
          this.$flashMessage.show({
            type: 'error',
            text: 'Misslyckades med att starta exporten. Vänligen försök igen.',
          })
        }).then(() => {
          this.axios.post('/internal_api/metrics', { key: `order.action.${action}`, value: 1 })

          if (action === 'start_netsuite_approve') {
            performanceTimer.stop('manual_order_time_to_approved', (duration) => {
              this.axios.post('/internal_api/metrics', { key: 'manual_order.preptime', value: duration / 1000.0 })
            })
            performanceTimer.stop('order_time_to_approve', (duration) => {
              this.axios.post('/internal_api/metrics', { key: 'order.time_to_approve', value: duration / 1000.0 })
            })
            performanceTimer.stop(`order_time_from_substitution_to_approve_${this.salesOrderId}`, (duration) => {
              this.axios.post('/internal_api/metrics', { key: 'order.time_from_substitution_to_approve', value: duration / 1000.0 })
            })
          }
        })
    },
    openNetsuiteLink () {
      window.open(this.$store.state.settings.ns_root_href + '/app/accounting/transactions/transaction.nl?id=' +
                  this.netsuiteInternalId)
    },
  },
}
</script>

<style scoped lang="scss">
  button {
    width: 13em;
  }

  header {
    display: flex;
    align-items: center;
    margin-bottom: .5rem;
    font-size: 1.125rem;
  }

  .large-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: .5rem;
    font-size: 1.4rem;
    width: 2.03125rem;
    height: 2.03125rem;

    &.stop {
      color: #fff;
      background-color: var(--tw-red-600);
      border-radius: .4rem;
    }

    &.ready {
      color: #fff;
      background-color: var(--tw-green-700);
      border-radius: 50%;
    }

    &.done {
      color: #fff;
      background-color: var(--apoex-clear-blue-base);
      border-radius: .3rem;
    }
  }
</style>
