<template>
  <VDropdown
    class="d-inline"
    :distance="6"
    :shown="show"
    :disabled="!enabled"
  >
    <div
      class="d-inline"
      data-test="popover-open-button"
      :role="enabled ? 'button' : 'text'"
    >
      {{ buttonText || '-' }}
      <i
        v-if="enabled"
        class="icon bi-pencil-fill align-top fs-14"
      />
    </div>
    <template #popper>
      <div class="grid p-4 popover-min-width">
        <div class="row">
          <div class="col-10 fs-16 fw-medium mb-2 ">
            {{ title }}
          </div>
          <div class="col-2 text-end">
            <button
              v-close-popper
              v-tooltip="'Stäng'"
              type="button"
              class="btn-close"
              aria-label="Stäng"
            />
          </div>
        </div>
        <div class="row">
          <slot
            name="form"
          >
            Loading...
          </slot>
        </div>
      </div>
    </template>
  </VDropdown>
</template>
<script>
export default {
  inject: ['axios'],
  props:  {
    title: {
      type:     String,
      required: true,
    },
    buttonText: {
      type:     String,
      required: true,
    },
    enabled: {
      type:     Boolean,
      required: true,
    },
  },
  data () {
    return {
      show: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.popover-min-width {
  min-width: 15rem;
}
</style>
