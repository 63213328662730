<script setup>
import VDefinition from '@/components/VDefinition.vue'
import { useAgreementsForLocation } from '@/composables/useAgreementsForLocation.js'

defineProps({
  selectedAgreement: {
    type:     String,
    required: false,
    default:  null,
  },
})
const emit = defineEmits(['update:selectedAgreement'])

const { agreements } = useAgreementsForLocation()
</script>

<template>
  <VDefinition
    label="Avtal"
  >
    <select
      :value="selectedAgreement || -1"
      class="form-select"
      data-test="agreement-select"
      @change="emit('update:selectedAgreement', $event.target.value)"
    >
      <option
        value="-1"
      >
        Alla
      </option>
      <option
        v-for="agreement in agreements"
        :key="agreement.id"
        :value="agreement.id"
      >
        {{ agreement.name }}
      </option>
    </select>
  </VDefinition>
</template>
