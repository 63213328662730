const validationConfig = {
  normal: {
    release_deadline: {
      validators: [
        {
          name:              'Frisläppt senast satt',
          type:              'block',
          errorText:         'Frisläpps senast är ej satt',
          expandedErrorText: 'Frisläpps senast är ej satt',
          case:              (releaseDeadline) => releaseDeadline !== null,
        },
      ],
      reference:       () => 'Frisläpps senast',
      showIdentifier:  () => 'release-deadline',
      showTooltipText: () => 'Visa frisläpps senast',
    },
    fulfillment_deadline: {
      validators: [
        {
          name:              'Expedieras senast satt',
          type:              'block',
          errorText:         'Expedieras senast är ej satt',
          expandedErrorText: 'Expedieras senast är ej satt',
          case:              (fulfillmentDeadline) => fulfillmentDeadline !== null,
        },
      ],
      reference:       () => 'Expedieras senast',
      showIdentifier:  () => 'fulfillment-deadline',
      showTooltipText: () => 'Visa expedieras senast',
    },
    latest_delivery_deadline: {
      validators: [
        {
          name:              'Levereras senast satt',
          type:              'block',
          errorText:         'Levereras senast är ej satt',
          expandedErrorText: 'Levereras senast är ej satt',
          case:              (latestDeliveryDeadline) => latestDeliveryDeadline !== null,
        },
      ],
      reference:       () => 'Levereras senast',
      showIdentifier:  () => 'latest-delivery-deadline',
      showTooltipText: () => 'Visa levereras senast',
    },
  },
  lists: {
    lines: {
      validators: [
        {
          name:              'Ej säljstoppad',
          type:              'block',
          errorText:         'Säljstoppad',
          expandedErrorText: 'Varan är säljstoppad',
          case:              (line) => !(line.item.sales_status === 'not_for_sale' && !line['zero_quantity?']),
        },
        {
          name:              'Har licens',
          type:              'block',
          errorText:         'Licens saknas',
          expandedErrorText: 'Kund saknar licens för varan',
          case:              (line) => !(line.item.article_type === 'non_licensed_drug' && !line['valid_license?'] && !line['zero_quantity?']),
        },
        {
          name:              'Ej upphandlat substitut på utförsäljning',
          type:              'lightWarning',
          errorText:         'Utförsäljning upphandlat',
          expandedErrorText: 'Minst ett upphandlat substitut på utförsäljning finns',
          case:              (line) => !(line['contracted_substitute_on_closing_sale?']),
        },
        {
          name:              'Ej utförsäljning för substitut på beredskapslager',
          type:              'lightWarning',
          errorText:         'Utförsäljning beredskapslager',
          expandedErrorText: 'Minst ett substitut har utförsäljning på beredskapslager',
          case:              (line) => !line['backup_storage_substitute_surplus?'],
        },
        {
          name:              'Ej upphandlat substitut',
          type:              'lightWarning',
          errorText:         'Upphandlat substitut',
          expandedErrorText: 'Minst ett upphandlat substitut finns',
          case:              (line) => !(line['contracted_substitute?']),
        },
        {
          name:              'Ej prioriterat substitut',
          type:              'lightWarning',
          errorText:         'Potential-substitut',
          expandedErrorText: 'Minst ett potentialsubstitut finns',
          case:              (line) => !(line['prioritized_substitute?'] && !line['contracted_price?']),
        },
        {
          name:              'Ej restnoterad hos leverantör',
          type:              'warning',
          errorText:         'Restnoterad leverantör',
          expandedErrorText: 'Varan är restnoterad hos leverantör',
          case:              (line) => !(parseFloat(line.quantity) > parseFloat(line.quantity_available) && line.wholesale_stock_status === 'not_in_stock' && !line['zero_quantity?']),
        },
        {
          name:              'Ej restnoterad',
          type:              'lightWarning',
          errorText:         'Restnoterad',
          expandedErrorText: 'Varan är restnoterad',
          case:              (line) => !(parseFloat(line.quantity) > parseFloat(line.quantity_available) && !line['zero_quantity?']),
        },
        {
          name:              'Ej säljstoppad',
          type:              'warning',
          errorText:         'Säljstoppad',
          expandedErrorText: 'Varan är säljstoppad',
          case:              (line) => !(line.item.sales_status === 'not_for_sale' && line['zero_quantity?']),
        },
        {
          name:              'Har licens',
          type:              'warning',
          errorText:         'Licens saknas',
          expandedErrorText: 'Kund saknar licens för varan',
          case:              (line) => !(line.item.article_type === 'non_licensed_drug' && !line['valid_license?'] && line['zero_quantity?']),
        },
      ],
      reference:       (line) => `${line.item.vendor_name} ${line.item.name}`,
      showIdentifier:  (line) => `sales-order-line-${line.id}`,
      showTooltipText: () => 'Visa orderraden',
    },
  },
  validatorTypes: {
    block: {
      preventApprove: true,
      priority:       1,
    },
    warning: {
      preventApprove: false,
      priority:       2,
    },
    lightWarning: {
      preventApprove: false,
      priority:       3,
    },
  },
}

export default validationConfig
