<template>
  <div :class="size">
    <div
      class="label d-flex"
      :class="{'required': required}"
    >
      {{ label }}
      <i
        v-if="tooltip"
        v-tooltip="tooltip"
        class="tooltip-icon bi bi-question-circle-fill"
      />
    </div>
    <div class="content">
      <slot>-</slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type:     String,
      required: true,
    },
    required: {
      type:    Boolean,
      default: false,
    },
    size: {
      type:    String,
      default: 'sm',
    },
    tooltip: {
      type:    String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
  .label {
    font-weight: 500;
    color: #6B7280;

    .tooltip-icon {
      font-size: .65rem;
      margin-left: .1rem;
    }
  }

  .content {
    font-weight: 500;
    color: #27272A;
  }

  .sm {
    .label {
      font-size: 12px;
    }
    .content {
      font-size: 13px;
    }
  }

  .md {
    .label {
      font-size: 12px;
    }
    .content {
      font-size: 16px;
    }
  }
</style>
