<script setup>
import VDefinition from '@/components/VDefinition.vue'
import { useFetch } from '@/composables/useFetch.js'

defineProps({
  selectedShippingMethod: {
    type:     String,
    required: false,
    default:  null,
  },
})
const emit = defineEmits(['update:selectedShippingMethod'])

const { data: shippingMethods } = useFetch('/internal_api/shipping_methods')
</script>

<template>
  <VDefinition
    label="Leveransmetod"
  >
    <select
      :value="selectedShippingMethod || -1"
      class="form-select"
      data-test="shipping-method-select"
      @change="emit('update:selectedShippingMethod', $event.target.value)"
    >
      <option
        value="-1"
      >
        Alla
      </option>
      <option
        v-for="shippingMethod in shippingMethods"
        :key="shippingMethod.id"
        :value="shippingMethod.id"
      >
        {{ shippingMethod.name }}
      </option>
    </select>
  </VDefinition>
</template>
