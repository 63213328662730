<template>
  <div class="container my-5 text-center">
    <h1 class="my-3">
      404 - Sidan finns inte
    </h1>
    <router-link :to="{ name: 'Home' }">
      Tillbaka till startsidan
    </router-link>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
