<template>
  <LetterDot
    v-if="isMissingLicense"
    color="red"
    letter="LS"
    tooltip="Licens saknas"
  />

  <LetterDot
    v-if="hasContractedPrice"
    color="purple"
    letter="U"
    tooltip="Upphandlad vara"
  />

  <LetterDot
    v-if="itemIsClosingSale"
    color="green"
    letter="UT"
    tooltip="Utförsäljning"
  />

  <LetterDot
    v-if="backupStorageSurplus"
    color="green"
    letter="UB"
    tooltip="Utförsäljning Beredskapslager"
  />

  <LetterDot
    v-if="specialHandlingInstructions"
    color="red"
    letter="SH"
    :tooltip="specialHandlingInstructions"
  />
</template>

<script>
import LetterDot from '@/components/LetterDot.vue'

export default {
  components: {
    LetterDot,
  },
  props: {
    isMissingLicense: {
      type:     Boolean,
      required: true,
    },
    hasContractedPrice: {
      type:     Boolean,
      required: true,
    },
    itemIsClosingSale: {
      type:     Boolean,
      required: true,
    },
    backupStorageSurplus: {
      type:     Boolean,
      required: true,
    },
    specialHandlingInstructions: {
      type:     String,
      required: false,
      default:  null,
    },
  },
}
</script>
