<template>
  <div class="fixed-top">
    <nav class="navbar navbar-expand-lg bg-white border-bottom">
      <div class="container">
        <router-link
          class="navbar-brand d-flex flex-column"
          to="/"
        >
          <img
            class="logo"
            :src="apoexLogo"
          >
        </router-link>
        <div class="collapse navbar-collapse">
          <ul class="navbar-nav">
            <li class="nav-item mx-4">
              <router-link
                class="router-link orders-index-link fs-5 link-dark text-uppercase text-decoration-none"
                to="/"
              >
                Ordrar
              </router-link>
            </li>
            <li class="nav-item link-dark mx-4">
              <router-link
                class="router-link fs-5 link-dark text-uppercase text-decoration-none"
                to="/sales_orders/new"
              >
                Ny Order
              </router-link>
            </li>
          </ul>
        </div>
        <div
          v-if="railsEnv != 'production'"
          class="text-end me-4 "
        >
          <div class="text-uppercase fw-semibold text-danger">
            {{ railsEnv }}
          </div>
          <div v-if="branchName">
            <a
              class="text-decoration-none text-muted"
              href="#"
              @click="openGithubLink"
            >
              <i class="bi bi-git" />
              {{ branchName }}
            </a>
          </div>
        </div>
        <AppNavbarUserMenu />
      </div>
    </nav>
  </div>
</template>

<script>
import AppNavbarUserMenu from '@/components/AppNavbarUserMenu.vue'
import { Images } from '@apoex/apoex-bootstrap-styles'

export default {
  components: {
    AppNavbarUserMenu,
  },
  inject: ['axios'],
  setup () {
    return {
      apoexLogo: Images.apoexLogoBlack,
    }
  },
  computed: {
    railsEnv () {
      return this.$store.state.settings.rails_env
    },
    branchName () {
      return this.$store.state.settings.branch_name
    },
  },
  methods: {
    signOut () {
      this.axios.delete('/sign_out')
        .then(() => {
          window.location.href = '/'
        })
    },
    openGithubLink () {
      window.open(`https://github.com/apoex/orbit/commit/${this.$store.state.settings.commit}`)
    },
  },
}
</script>

<style scoped lang="scss">
  .logo {
    height: 1.7rem;
    margin-top: .3rem;
  }

  .nav-item:hover .router-link {
    padding-bottom: .2rem;
    border-bottom: 2px solid var(--apoex-clear-blue-base);
  }
</style>
