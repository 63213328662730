<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'
import SalesOrdersIndexFilterSelectorRegionSelect from '@/components/SalesOrdersIndexFilterSelectorRegionSelect.vue'
import SalesOrdersIndexFilterSelectorAgreementSelect from '@/components/SalesOrdersIndexFilterSelectorAgreementSelect.vue'
import SalesOrdersIndexFilterSelectorLoadingDockSelect from './SalesOrdersIndexFilterSelectorLoadingDockSelect.vue'
import SalesOrdersIndexFilterSelectorShippingMethodSelect from './SalesOrdersIndexFilterSelectorShippingMethodSelect.vue'

const store = useStore()

const props = defineProps({
  selectedFilters: {
    type:     Object,
    required: true,
  },
})
const emit = defineEmits(['update:selectedFilters'])

const updateFilters = (key, value) => {
  const newFilters = { ...props.selectedFilters }

  if (value === '-1') {
    delete newFilters[key]
  } else {
    newFilters[key] = value
  }

  emit('update:selectedFilters', newFilters)
}

const useAgreements = computed(() => store.state.settings.use_agreements)
</script>

<template>
  <div class="container ">
    <div class="row ">
      <div class="col-2">
        <SalesOrdersIndexFilterSelectorAgreementSelect
          v-if="useAgreements"
          :selected-agreement="selectedFilters.agreement"
          @update:selected-agreement="updateFilters('agreement', $event)"
        />
        <SalesOrdersIndexFilterSelectorRegionSelect
          v-else
          :selected-region="selectedFilters.region"
          @update:selected-region="updateFilters('region', $event)"
        />
      </div>
      <div class="col-2">
        <SalesOrdersIndexFilterSelectorLoadingDockSelect
          :selected-loading-dock="selectedFilters.loadingDock"
          @update:selected-loading-dock="updateFilters('loadingDock', $event)"
        />
      </div>
      <div class="col-2">
        <SalesOrdersIndexFilterSelectorShippingMethodSelect
          :selected-shipping-method="selectedFilters.shippingMethod"
          @update:selected-shipping-method="updateFilters('shippingMethod', $event)"
        />
      </div>
    </div>
  </div>
</template>
