<template>
  <tr
    :class="['message', expanded ? 'expanded' : '']"
    @click="toggleExpanded"
  >
    <td>
      {{ dateFormat(message.created_at, "ddd, d mmm") }}
    </td>

    <td>
      {{ message.backoff_until ? dateFormat(message.backoff_until, "ddd, d mmm HH:MM") : "-" }}
    </td>

    <td>
      {{ message.payload.order_reference || "N/A" }}
    </td>

    <td>
      {{ message.payload.consignee_gln || "N/A" }}
    </td>

    <td>
      {{ resourceTranslation }}
    </td>

    <td class="text-end px-3">
      <i
        v-if="expanded"
        class="bi-chevron-up"
      />
      <i
        v-else
        class="bi-chevron-down"
      />
    </td>
  </tr>
  <tr
    v-if="expanded"
    class="expanded"
  >
    <td colspan="6">
      <div class="d-flex flex-row w-100 my-2 p-2">
        <div class="d-flex flex-column gap-3 flex-grow-1">
          <div class="d-flex align-items-center justify-content-between mb-3">
            <h5 class="mb-0">Felmeddelande:</h5>
            <div>
              <v-btn
                v-if="isPharmacist"
                prepend-icon="mdi-check"
                @click="acknowledgeMessage"
                class="bg-red mr-2"
              >
                Ta bort order
              </v-btn>
              <v-btn
                v-if="isPharmacist"
                prepend-icon="mdi-reload"
                @click="retryMessage"
                class="bg-blue"
              >
                Försök läsa in meddelande igen
              </v-btn>
            </div>
          </div>
          <div>
            <span>
              {{ message.error }}
            </span>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script lang="ts">
import { ref } from "vue"
import dateFormat from "dateformat"
import currencySEK from "@/composables/currencySEK.js"

const resourceTranslations = {
  ecommerce_sales_order: "Care",
  eddie_sales_order: "LMS",
}

export default {
  components: {},
  inject: ["axios"],
  props: {
    inboxMessage: {
      type: Object,
      required: true,
    },
    onAcknowledge: {
      type: Function,
      required: true,
    },
    isPharmacist: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const message = ref(props.inboxMessage)

    return {
      currencySEK,
      dateFormat,
      message,
    }
  },
  data() {
    return {
      expanded: false,
    }
  },
  computed: {
    resourceTranslation() {
      return resourceTranslations[this.message.resource] || "Okänd"
    },
  },
  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded
    },
    acknowledgeMessage() {
      this.axios
        .patch(`/internal_api/sales_orders/${this.message.key}/acknowledge`, {})
        .then(() => {
          this.onAcknowledge(this.message.key)
        })
    },
    retryMessage() {
      this.axios
        .patch(`/internal_api/sales_orders/${this.message.key}/retry`, {})
        .then(() => {
          this.$flashMessage.show({
            type: "success",
            text: "Ordern har nu lästs in",
          })
          this.onAcknowledge(this.message.key)
        })
        .catch((error) => {
          console.log(error)
          this.$flashMessage.show({
            type: "error",
            text: error.response.data.message,
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.message {
  cursor: pointer;
  color: var(--tw-slate-700);
}

.expanded {
  background-color: var(--bs-secondary) !important;
}
</style>
