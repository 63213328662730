<template>
  <div class="row pb-2">
    <div class="fs-14 text-tw-slate-500">
      <span :class="{ 'text-danger': totalBlocks > 0 }">
        {{ $t('validationSummary.totalBlocks', {count: totalBlocks}) }}
      </span>
      <span>, </span>
      <span :class="{ 'text-warning': totalWarnings > 0 }">
        {{ $t('validationSummary.totalWarnings', {count: totalWarnings}) }}
      </span>
    </div>
  </div>

  <div class="p-0 fs-14">
    <ul
      v-if="validationSummary.errorGroups.length"
      class="list-group rounded-0 border-bottom"
    >
      <li
        v-for="(errorGroup, index) in validationSummary.errorGroups"
        :key="index"
        class="list-group-item bg-tw-slate-50 border-0 border-top p-0"
      >
        <VCollapse v-if="errorGroup.errors.length > 1">
          <template #button="{toggled}">
            <div class="p-2 collapse-button">
              <span class="pe-2">
                <i :class="iconStyleForErrorType(errorGroup.type)" />
              </span>
              <span class="fw-medium">
                {{ errorGroup.reference }}
              </span>
              <i
                v-if="errorGroup.showIdentifier"
                class="bi bi-eye clickable float-end"
                @click.stop="scrollToAndClick(errorGroup.showIdentifier)"
              />
              <i
                class="bi bi-chevron-down collapse-toggle float-end pe-2"
                :class="{rotated: toggled}"
              />
            </div>
          </template>

          <template #collapse-content>
            <ul class="list-group gx-0 rounded-0">
              <li
                v-for="(error, errorIndex) in errorGroup.errors"
                :key="`${index}-${errorIndex}`"
                class="list-group-item border-0 border-top"
              >
                <span class="px-2">
                  <i :class="iconStyleForErrorType(error.type)" />
                </span>
                {{ error.expandedText }}
              </li>
            </ul>
          </template>
        </VCollapse>

        <div
          v-else
          class="p-2"
        >
          <span class="pe-2">
            <i :class="iconStyleForErrorType(errorGroup.type)" />
          </span>
          <span class="fw-medium">
            {{ errorGroup.reference }}:
          </span>
          <span class="fst-italic">
            {{ errorGroup.errors[0].expandedText }}
          </span>
          <i
            v-if="errorGroup.showIdentifier"
            v-tooltip="errorGroup.showTooltipText"
            class="bi bi-eye clickable float-end"
            @click="scrollToAndClick(errorGroup.showIdentifier)"
          />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import VCollapse from '@/components/VCollapse.vue'

export default {
  components: {
    VCollapse,
  },
  props: {
    validationSummary: {
      type:     Object,
      required: true,
    },
  },
  computed: {
    totalBlocks () {
      return this.validationSummary.typeCount.block
    },
    totalWarnings () {
      return this.validationSummary.typeCount.warning + this.validationSummary.typeCount.lightWarning
    },
  },
  methods: {
    scrollToAndClick (identifier) {
      const el = document.getElementById(identifier)

      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center' })
        el.click()
      }
    },
    iconStyleForErrorType (errorType) {
      switch (errorType) {
        case 'block':
          return 'bi bi-exclamation-diamond text-danger'
        case 'lightWarning':
        case 'warning':
          return 'bi bi-exclamation-triangle text-warning'
        default:
          return 'bi bi-question'
      }
    },
  },
}
</script>

<style scoped lang="scss">
  .clickable {
    cursor: pointer;
  }

  .collapse-button:hover {
    background: var(--tw-slate-100)
  }

  .collapse-toggle {
    transition: all 0.3s ease;
    transform-origin: 33% 50%;
  }

  .collapse-toggle.rotated {
    transform: rotate(-180deg);
  }
</style>
