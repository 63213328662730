import axios from 'axios'

const instance = axios.create()

instance.defaults.headers.common = {
  'X-CSRF-TOKEN': document.querySelector("meta[name='csrf-token']")?.getAttribute('content'),
}

instance.interceptors.response.use((res) => {
  return Promise.resolve(res)
}, (error) => {
  if (error != null && error.response.status === 401) {
    window.location.href = '/'
  }
  return Promise.reject(error)
})

export default instance
