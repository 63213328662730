import { watch, computed } from 'vue'
import { useFetch } from '@/composables/useFetch'
import { useStore } from 'vuex'

export function useAgreementsForLocation () {
  const store = useStore()

  const locationId = computed(() => {
    return store.state.settings.current_user.location.id
  })

  const { data: agreements, fetch: fetchAgreements } = useFetch(
    '/internal_api/agreements',
    {},
    { immediate: false, root: ['agreements'] },
  )

  const fetchAgreementsForLocation = () => {
    fetchAgreements({ params: { ship_from_location: locationId.value } })
  }

  watch(locationId, () => {
    fetchAgreementsForLocation()
  })

  fetchAgreementsForLocation()

  return { agreements }
}
