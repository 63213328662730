<template>
  <VBadge
    v-if="itemIsNarcotic"
    icon="bi-exclamation-diamond"
    color="yellow"
  >
    Narkotika
  </VBadge>

  <VBadge
    v-if="itemIsLicensed"
    icon="bi-card-checklist"
    color="pink"
  >
    Licensvara
  </VBadge>

  <TemperatureBadge
    v-if="item.temperature_class && item.temperature_class !== 'normal'"
    :temperature-class="item.temperature_class"
  />

  <VBadge
    v-if="itemIsFullPackage"
    icon="bi-box-seam"
    color="green"
  >
    Helkolli
  </VBadge>
</template>

<script>
import VBadge from '@/components/VBadge.vue'
import TemperatureBadge from '@/components/TemperatureBadge.vue'

export default {
  components: {
    VBadge,
    TemperatureBadge,
  },
  props: {
    item: {
      type:     Object,
      required: true,
    },
  },
  computed: {
    itemIsNarcotic () {
      return this.item.is_narcotics_classed
    },
    itemIsLicensed () {
      return this.item.article_type === 'non_licensed_drug'
    },
    itemIsFullPackage () {
      return this.item.full_package
    },
  },
}
</script>
