<template>
  <div
    role="button"
    @click="toggle"
  >
    <slot
      name="button"
      :toggled="show"
    />
  </div>

  <div
    :id="identifier"
    class="collapse"
  >
    <slot
      name="collapse-content"
    />
  </div>
</template>

<script>
import { Collapse } from 'bootstrap'

export default {
  data () {
    return {
      show:                     false,
      boostrapCollapseInstance: null,
    }
  },
  computed: {
    identifier () {
      return `collapse-${this.$.uid}`
    },
  },
  watch: {
    show () {
      this.show ? this.boostrapCollapseInstance.show() : this.boostrapCollapseInstance.hide()
    },
  },
  mounted () {
    const element = document.getElementById(this.identifier)
    this.boostrapCollapseInstance = new Collapse(element, { toggle: false })
  },
  methods: {
    toggle () {
      this.show = !this.show
    },
  },
}
</script>

<style scoped lang="scss">
</style>
