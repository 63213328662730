import { watch, computed } from 'vue'
import { useFetch } from '@/composables/useFetch'
import { useStore } from 'vuex'

export function useRegionsForLocation () {
  const store = useStore()

  const locationId = computed(() => {
    return store.state.settings.current_user.location.id
  })

  const { data: regions, fetch: fetchRegions } = useFetch(
    '/internal_api/regions',
    {},
    { immediate: false, root: ['regions'] },
  )

  const fetchRegionsForLocation = () => {
    fetchRegions({ params: { ship_from_location: locationId.value } })
  }

  watch(locationId, () => {
    fetchRegionsForLocation()
  })

  fetchRegionsForLocation()

  return { regions }
}
