<template>
  <div v-if="address">
    <div>{{ address.addressee }}</div>
    <div>{{ address.addr1 }}</div>
    <div>{{ address.addr2 }}</div>
    <div>{{ address.zip }} {{ address.city }}</div>
  </div>
  <div v-else>
    -
  </div>
</template>

<script>
export default {
  props: {
    address: {
      type:    Object,
      default: () => {},
    },
  },
}
</script>

<style lang="css" scoped>
</style>
