<template>
  <div
    class="pt-2 w-100"
    data-test="export-error-display"
  >
    <div
      class="alert alert-danger"
      role="alert"
    >
      <h4 class="alert-heading">
        Ordern kunde inte exporteras till NetSuite!
      </h4>
      <p>
        Ett fel uppstod när ordern skickades till Netsuite. Vi fick följande felmeddelande:
      </p>
      <hr>
      <h6>
        {{ exportError.class }}
      </h6>
      <p class="mb-0">
        {{ exportError.message }}
      </p>
      <a
        href="#"
        data-bs-toggle="offcanvas"
        data-bs-target="#help-offcanvas"
      >
        Skapa ett supportärende&raquo;
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    exportError: {
      type:     Object,
      required: false,
      default:  () => {},
    },
  },
}
</script>

<style scoped lang="scss">
</style>
