<template>
  <VDefinition
    label="Välj anledning"
    required
  >
    <select
      v-model="reason"
      class="form-select"
      :class="{'is-invalid': v$.reason.$error}"
      data-test="exchange-reason"
      @change="selectExchangeReason"
    >
      <option
        v-for="(_id, name) in exchangeReasons"
        :key="name"
        :value="name"
      >
        {{ $t(`exchangeReasons.${name}`) }}
      </option>
    </select>
  </VDefinition>
</template>

<script>

import { ref } from 'vue'
import VDefinition from '@/components/VDefinition.vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@/utils/i18n-validators'

export default {
  components: {
    VDefinition,
  },
  props: {
    icon: {
      type:    String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup (props) {
    return {
      exchangeReasons: ref([]),
      reason:          ref(null),
      v$:              useVuelidate(),
    }
  },
  created () {
    this.exchangeReasons = this.$store.state.settings.exchange_reasons
  },
  methods: {
    selectExchangeReason (event) {
      this.$emit('update:modelValue', this.reason)
    },
  },
  validations () {
    return {
      reason: {
        required,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
