<template>
  <div>
    <div class="d-flex align-items-baseline overflow-hidden">
      <div class="fs-15 font-monospace me-1 fw-semibold">
        {{ item.vendor_name }}
      </div>
      <div class="fw-medium">
        {{ item.name }}
      </div>
    </div>

    <div>
      {{ item.details_text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type:     Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
