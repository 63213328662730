<template>
  <div>
    <div class="container">
      <h2 class="mt-4">
        Godkända Säljordrar
      </h2>
      <p class="fst-italic">
        Här visas säljordrar som tidigare har behandlats
      </p>
    </div>

    <div class="container-sm mt-5">
      <Loading
        :active="loading"
        :is-full-page="false"
      />
      <div class="bg-white rounded-3 shadow-sm p-3">
        <div class="row">
          <div class="col-6 offset-6">
            <div class="input-group flex-nowrap">
              <span class="input-group-text">
                <i class="bi-search" />
              </span>
              <input
                v-model="query"
                type="search"
                class="form-control"
                placeholder="Order-nr, Kunds order-nr, Kundnamn, Kund-nr"
                @keydown.enter="search()"
              >
              <button
                class="btn btn-secondary"
                type="button"
                @click="search()"
              >
                Sök
              </button>
            </div>
          </div>
        </div>

        <table class="table table-hover table-striped">
          <thead>
            <tr class="align-bottom fs-15">
              <th>Beställdes</th>
              <th id="order-number">
                Order-nr
              </th>
              <th id="customer-order-number">
                Kunds Order-nr
              </th>
              <th>
                <div class="grid">
                  <div class="row">
                    <div class="col">
                      KUND
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8">
                    Beställare
                  </div>
                  <div class="col-4">
                    <span v-if="useAgreements">
                      Avtal
                    </span>
                    <span v-else>
                      Region
                    </span>
                  </div>
                </div>
              </th>
              <th>Lastkaj</th>
              <th>Leveransmetod</th>
            </tr>
          </thead>
          <tbody>
            <SalesOrdersApprovedIndexTableRow
              v-for="order in salesOrders"
              :key="order.id"
              :order="order"
            />
          </tbody>
        </table>
      </div>

      <div class="d-flex flex-row-reverse pt-3">
        <PageSelector
          v-if="pageInfo"
          :page-info="pageInfo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PageSelector from '@/components/PageSelector.vue'
import SalesOrdersApprovedIndexTableRow from '@/components/SalesOrdersApprovedIndexTableRow.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  components: {
    Loading,
    PageSelector,
    SalesOrdersApprovedIndexTableRow,
  },
  inject: ['axios'],
  data () {
    return {
      salesOrders: [],
      pageInfo:    null,
      loading:     false,
      query:       '',
    }
  },
  computed: {
    locationId () {
      return this.$store.state.settings.current_user.location.id
    },
    useAgreements () {
      return this.$store.state.settings.use_agreements
    },
  },
  watch: {
    $route: {
      handler: function () {
        this.fetchApprovedSalesOrders()
      },
    },
    locationId: {
      handler: function () {
        this.$router.replace({ query: { search: undefined } })
        this.fetchApprovedSalesOrders()
      },
    },
  },
  mounted () {
    this.query = this.$route.query.q
    this.fetchApprovedSalesOrders()
  },
  methods: {
    search () {
      this.$router.replace({ path: '/sales_orders/approved', query: { q: this.query } })
      this.fetchApprovedSalesOrders()
    },
    fetchApprovedSalesOrders () {
      this.loading = true
      this.axios.get('/internal_api/sales_orders', {
        params: {
          page:        this.getCurrentPage(),
          location_id: this.$store.state.settings.current_user.location.id,
          approved:    true,
          q:           this.query,
        },
      }).then((response) => {
        this.salesOrders = response.data.sales_orders
        this.pageInfo = response.data.page_info
      }).catch((_response) => {
        this.$flashMessage.show({
          type: 'error',
          text: 'Ett oväntat fel har inträffat. Var vänlig försök igen.',
        })
      }).then(() => {
        this.loading = false
      })
    },
    getCurrentPage () {
      return this.$route.query.page
    },
  },
}

</script>

<style scoped>
#customer-order-number,
#order-number {
  width: 12%;
}
</style>
