import { ref, watchEffect } from 'vue'
import validationConfig from '@/utils/salesOrderValidationConfig.js'

export default function useOrderValidator (order) {
  const validationResult = ref(null)
  const initialTypeCount = Object.keys(validationConfig.validatorTypes).reduce((countHolder, errorType) => {
    countHolder[errorType] = 0
    return countHolder
  }, {})

  function reset () {
    validationResult.value = {
      errors: {
        normal: {},
        lists:  {},
      },
      summary: {
        approvable:  true,
        typeCount:   Object.assign({}, initialTypeCount),
        errorGroups: [],
      },
    }
  }

  function runValidator (validator, item) {
    if (validator.case(item)) return { success: true, error: null }

    const error = {
      type:         validator.type,
      text:         validator.errorText,
      expandedText: validator.expandedErrorText,
    }

    return { success: false, error: error }
  }

  function applyError (error, errors) {
    errors.push(error)

    validationResult.value.summary.typeCount[error.type]++
    if (validationConfig.validatorTypes[error.type].preventApprove) validationResult.value.summary.approvable = false
  }

  function applyErrorsToSummary (errors, item, attributeConfig, groupType) {
    const errorGroup = {
      errors:          errors,
      reference:       attributeConfig.reference(item),
      showIdentifier:  attributeConfig.showIdentifier(item),
      showTooltipText: attributeConfig.showTooltipText(item),
      type:            groupType,
    }

    validationResult.value.summary.errorGroups.push(errorGroup)
  }

  function applyAttributeConfig (attributeConfig, item, errors) {
    let groupType = null

    attributeConfig.validators.forEach((validator) => {
      const result = runValidator(validator, item)

      if (!result.success) {
        applyError(result.error, errors)

        if (groupType == null ||
            validationConfig.validatorTypes[result.error.type].priority <
            validationConfig.validatorTypes[groupType].priority) {
          groupType = result.error.type
        }
      }
    })

    if (errors.length > 0) applyErrorsToSummary(errors, item, attributeConfig, groupType)
  }

  function validateNormalValidations () {
    Object.entries(validationConfig.normal).forEach(([attribute, attributeConfig]) => {
      const item = order.value[attribute]
      const errors = []

      validationResult.value.errors.normal[attribute] = errors

      applyAttributeConfig(attributeConfig, item, errors)
    })
  }

  function validateListValidations () {
    Object.entries(validationConfig.lists).forEach(([listName, attributeConfig]) => {
      const listObject = {}
      validationResult.value.errors.lists[listName] = listObject

      order.value[listName].forEach(item => {
        const errors = []
        listObject[item.id] = errors

        applyAttributeConfig(attributeConfig, item, errors)
      })
    })
  }

  function validate () {
    if (order.value === undefined || order.value === null) return

    reset()
    validateListValidations()
    validateNormalValidations()
  }

  watchEffect(validate) // Watch changes on specific elements being validated instead to improve performance?

  return { validationResult }
}
