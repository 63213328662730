<template>
  <div
    v-click-outside="stopEditing"
    class="autocomplete"
  >
    <div
      v-show="value"
      class="px-1 py-2"
    >
      <a
        class="btn btn-sm btn-outline-dark float-end"
        @click.prevent="clearValue()"
      >
        <i class="bi-pencil-fill" />
        Ändra
      </a>
      <slot
        v-if="value"
        name="selected-item"
        :item="value"
      >
        {{ value }}
      </slot>
    </div>

    <input
      v-show="!value"
      :id="id"
      ref="search"
      v-model="query"
      type="search"
      :name="name"
      :placeholder="placeholder"
      class="form-control"
      :class="{'is-invalid': hasValidationError}"
      autocomplete="off"
      data-test="autocomplete-input"
      @search="handleSearchEvent()"
      @keydown.arrow-up.prevent="markPrevious"
      @keydown.arrow-down.prevent="markNext"
      @keydown.enter.prevent="selectMarked"
    >

    <ul
      v-show="showOptions()"
      class="list-group"
    >
      <li
        v-show="query.length > 0 && query.length < minimumCharacters"
        class="list-group-item text-center"
      >
        Ange minst {{ minimumCharacters }} tecken
      </li>

      <li
        v-show="requestInProgress"
        class="list-group-item text-center"
      >
        <div class="text-center">
          <div
            class="spinner-border text-primary"
            role="status"
          >
            <span class="visually-hidden">Söker...</span>
          </div>
          <p>Söker...</p>
        </div>
      </li>

      <li
        v-show="query.length >= minimumCharacters && !requestInProgress && editing"
        class="list-group-item text-end fs-14"
      >
        Visar {{ matches.length }} träffar
      </li>

      <li
        v-for="(item, index) in matches"
        :key="item.id"
        class="list-group-item clickable"
        :class="generateMatchClasses(index)"
        data-test="autocomplete-match"
        @mousemove="markedIndex = index"
        @click="setValue(item)"
      >
        <slot
          name="list-item"
          :item="item"
          :query="query"
        />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    inputClass: {
      type:    Object,
      default: () => {},
    },
    id: {
      type:    String,
      default: null,
    },
    minimumCharacters: {
      type:    Number,
      default: 3,
    },
    name: {
      type:    String,
      default: null,
    },
    placeholder: {
      type:    String,
      default: '',
    },
    options: {
      type:    Array,
      default: () => {},
    },
    value: {
      type:    Object,
      default: null,
    },
    hasValidationError: {
      type:    Boolean,
      default: false,
    },
  },
  emits: ['option-selected', 'query-changed'],
  data () {
    return {
      query:             '',
      matches:           [],
      requestInProgress: false,
      markedIndex:       0,
      editing:           false,
    }
  },
  watch: {
    query (newValue) {
      if (newValue.length >= this.minimumCharacters && !this.value) {
        this.requestInProgress = true
        this.$emit('query-changed', newValue)
      } else if (newValue.length < this.minimumCharacters) {
        this.matches = []
      }
      this.editing = true
      this.markedIndex = 0
    },
    options (newOptions) {
      this.requestInProgress = false
      this.matches = newOptions
    },
  },
  methods: {
    setValue (item) {
      this.$emit('option-selected', item)

      this.matches = []
    },
    handleSearchEvent () {
      // Reset input value if user clicks the x button
      if (this.query === '') {
        this.$emit('option-selected', null)
      }
    },
    showOptions () {
      return this.requestInProgress || (!this.value && this.query.length > 0)
    },
    clearValue () {
      this.query = ''
      this.markedIndex = 0
      this.$emit('option-selected', null)

      this.$nextTick(() => {
        this.$refs.search.focus()
      })
    },
    generateMatchClasses (index) {
      let classes = 'match-' + index
      if (index === this.markedIndex) {
        classes += ' marked'
      }
      return classes
    },
    stopEditing (event) {
      this.editing = false
      this.matches = []
    },
    markPrevious () {
      if (this.markedIndex > 0) {
        this.markedIndex--
      } else {
        this.markedIndex = this.matches.length - 1
      }
      this.scrollToMarked()
    },
    markNext () {
      if (this.markedIndex < this.matches.length - 1) {
        this.markedIndex++
      } else {
        this.markedIndex = 0
      }
      this.scrollToMarked()
    },
    selectMarked () {
      this.setValue(this.matches[this.markedIndex])
    },
    scrollToMarked () {
      const el = this.$el.getElementsByClassName('match-' + this.markedIndex)[0]

      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
      }
    },
    focusInput () {
      this.$refs.search.focus()
    },
  },
}
</script>

<style lang="scss">
.autocomplete {
  position: relative;

  ul {
    position: absolute;
    width: 100%;
    z-index: 999;
    max-height: 350px;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow-y: auto;
    box-shadow: 2px 2px 12px #eee;

    li.clickable {
      cursor: pointer;
    }
  }

  .marked {
    background-color: #e0cffc;
  }

  .highlighted-text {
    background-color: #fea35d;
  }
}
</style>
