<template>
  <div class="row py-2 bg-tw-gray-200 border-bottom">
    <div class="col-1 text-uppercase fw-semibold">
      Byt vara
    </div>

    <div class="col">
      <VDefinition label="Filtrering">
        <div class="form-check form-check-inline">
          <input
            id="amount-checkbox"
            v-model="amount"
            class="form-check-input"
            type="checkbox"
            @change="applyFilter"
          >
          <label for="amount-checkbox">Storlek</label>
        </div>
      </VDefinition>
    </div>

    <div class="col">
      <VDefinition label="Utanför Läkemedelsverkets utbytesgruppering">
        <div class="form-check form-check-inline">
          <input
            id="form-checkbox"
            v-model="form"
            class="form-check-input"
            type="checkbox"
            :disabled="atcCode === true"
            @change="applyFilter"
          >
          <label for="form-checkbox">Form</label>
        </div>

        <div class="form-check form-check-inline">
          <input
            id="strength-checkbox"
            v-model="strength"
            class="form-check-input"
            type="checkbox"
            :disabled="atcCode === true"
            @change="applyFilter"
          >
          <label for="strength-checkbox">Styrka</label>
        </div>

        <div class="form-check form-check-inline">
          <input
            id="atc-code-checkbox"
            v-model="atcCode"
            class="form-check-input"
            type="checkbox"
            @change="applyFilter"
          >
          <label for="atc-code-checkbox">ATC kod</label>
        </div>
      </VDefinition>
    </div>

    <div class="col">
      <button
        class="btn btn-sm btn-outline-dark shadow-sm mt-1 float-end"
        data-test="showFreeSubstitutionFormButton"
        @click.stop="showFreeSubstitutionForm = true"
      >
        Substansbyte
      </button>
    </div>
  </div>
  <div
    v-if="loading"
    class="line row"
  >
    <div class="col text-center p-3">
      <div class="spinner-border spinner-border-sm" />
      Hämtar möjliga utbyten...
    </div>
  </div>
  <div
    v-else
    class="substitutions"
  >
    <div class="table-header fs-13 row text-uppercase">
      <div class="col-1">
        &nbsp;
      </div>
      <div class="col-5">
        Vara
        <div class="fs-11 row">
          <div class="col-4">
            Form
          </div>
          <div class="col-2 ms-3">
            Styrka
          </div>
          <div class="col-2">
            Storlek
          </div>
          <div class="col-3">
            Förpackningstyp
          </div>
        </div>
      </div>
      <div class="col-3">
        Lagersaldo
        <div class="fs-11 row">
          <div class="col-5">
            BELA-saldo
            <i
              v-tooltip="'Aktuellt saldo på beredskapslagret. Tillängligt för utförsäljning / Totalt på beredskapslagret'"
              class="tooltip-icon bi bi-question-circle-fill font-color-grey"
            />
          </div>
          <div class="col-5">
            Leverantör
          </div>
        </div>
      </div>
      <div class="col-1">
        Pris
      </div>
      <div class="col">
        <div class="legend-container float-end">
          <LetterDot
            class="mt-2"
            color="gray"
            letter="?"
          />
          <div class="legend rounded-3 shadow p-1">
            <div>
              <div>
                <LetterDot
                  class="m-1"
                  color="blue"
                  letter="L"
                />
                Lagerförd vara
              </div>
              <LetterDot
                class="m-1"
                color="red"
                letter="LS"
              />
              Licens saknas
            </div>
            <div>
              <LetterDot
                class="m-1"
                color="purple"
                letter="U"
              />
              Upphandlad vara
            </div>
            <div>
              <LetterDot
                class="m-1"
                color="green"
                letter="UT"
              />
              Utförsäljning
            </div>
            <div>
              <LetterDot
                class="m-1"
                color="green"
                letter="UB"
              />
              Utförsäljning Beredskapslager
            </div>
            <div>
              <LetterDot
                class="m-1"
                color="red"
                letter="SH"
              />
              Särskild hantering
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="substitutionItems?.length"
    >
      <div
        v-for="item in substitutionItems"
        :key="item.vendor_name"
        class="line row border-bottom pt-2"
        :class="{active : activeSubstitutionItem === item}"
        data-test="substitution-item"
        @click="setActiveSubstitutionItem(item)"
      >
        <SubstitutionItem :item="item" />

        <SubstitutionItemConfirmForm
          v-if="activeSubstitutionItem == item"
          :quantity="parseFloat(salesOrderLine.quantity)"
          :comment="salesOrderLine.exchange_comment"
          :minimum-quantity="item.minimum_quantity"
          @confirm="(data) => selectSubstitutionItem(item, data)"
          @cancel="activeSubstitutionItem = null"
        />
      </div>
    </div>
    <div
      v-else
      class="no-results row border-bottom py-1"
    >
      <div class="col text-center fw-bold p-3">
        Inga matchande utbyten hittades
      </div>
    </div>
  </div>

  <VModal
    :open="showFreeSubstitutionForm"
    title="Genomför substansbyte"
    size="lg"
    @close="showFreeSubstitutionForm = false"
  >
    <FreeSubstitutionForm
      :sales-order-id="salesOrderId"
      :sales-order-line="salesOrderLine"
      @update-completed="(data) => $emit('salesOrderLineUpdated', data)"
    />
  </VModal>
</template>

<script>
import LetterDot from '@/components/LetterDot.vue'
import SubstitutionItem from '@/components/SubstitutionItem.vue'
import SubstitutionItemConfirmForm from '@/components/SubstitutionItemConfirmForm.vue'
import VModal from '@/components/VModal.vue'
import FreeSubstitutionForm from '@/components/FreeSubstitutionForm.vue'
import VDefinition from '@/components/VDefinition.vue'
import performanceTimer from '@/composables/performanceTimer.js'

export default {
  components: {
    LetterDot,
    SubstitutionItem,
    SubstitutionItemConfirmForm,
    VModal,
    FreeSubstitutionForm,
    VDefinition,
  },
  inject: ['axios'],
  props:  {
    salesOrderLine: {
      type:    Object,
      default: () => {},
    },
    salesOrderId: {
      type:    Number,
      default: null,
    },
  },
  emits: ['salesOrderLineUpdated', 'substitutionsDisplayed'],
  data () {
    return {
      strength:                 true,
      form:                     true,
      amount:                   true,
      atcCode:                  false,
      loading:                  false,
      activeSubstitutionItem:   null,
      substitutionItems:        null,
      showFreeSubstitutionForm: false,
    }
  },
  mounted () {
    this.$emit('substitutionsDisplayed')
    performanceTimer.start(`orderline_item_substitution_${this.salesOrderLine.id}`)
    performanceTimer.start(`order_time_from_substitution_to_approve_${this.salesOrderId}`)

    this.getSubstitutionItems()
  },
  methods: {
    getSubstitutionItems () {
      this.loading = true
      this.axios.get(
        '/internal_api/sales_order_lines/' + this.salesOrderLine.id + '/substitutions',
        {
          params: {
            form:     this.form,
            strength: this.strength,
            amount:   this.amount,
            atc_code: this.atcCode,
          },
        },
      ).then((response) => {
        this.substitutionItems = response.data.substitution_items
      }).catch((response) => {
        const message = (response.data && response.data.errors) || 'Något gick fel. Försök igen senare.'
        this.$flashMessage.show({
          type: 'error',
          text: message,
        })
      }).then(() => {
        this.loading = false
      })
    },
    selectSubstitutionItem (item, data) {
      this.axios.put(
        '/internal_api/sales_orders/' + this.salesOrderId,
        {
          lines_attributes: [
            {
              id:               this.salesOrderLine.id,
              item_id:          item.id,
              exchange_comment: data.comment,
              exchange_reason:  data.reason,
              quantity:         data.quantity,
            },
          ],
        },
      ).then((response) => {
        const salesOrder = response.data.data
        const updatedLine = salesOrder.lines.find((line) => line.id === this.salesOrderLine.id)

        this.$emit('salesOrderLineUpdated', updatedLine)
        performanceTimer.stop(`orderline_item_substitution_${this.salesOrderLine.id}`, (duration) => {
          this.axios.post('/internal_api/metrics', { key: 'order_line.substitute_item', value: duration / 1000.0 })
        })
      }).catch((error) => {
        const message = error.response.data?.message?.errors?.join(', ') || 'Något gick fel. Försök igen senare.'
        this.$flashMessage.show({
          type: 'error',
          text: message,
        })
      })
    },
    setActiveSubstitutionItem (item) {
      if (this.activeSubstitutionItem !== item) {
        this.activeSubstitutionItem = item
      }
    },
    applyFilter () {
      if (this.atcCode === true) {
        this.strength = false
        this.form = false
      }

      this.getSubstitutionItems()
    },
  },
}
</script>

<style lang="scss" scoped>
  .substitutions {
    z-index: -1;
    color: var(--tw-gray-800);

    .table-header {
      background: var(--tw-gray-100);
      border-color: var(--tw-gray-300);
      border-style: solid;
      border-width: 0 0 1px 0;
    }
    .line:nth-child(odd) {
      background: #ffffff;
    }
    .line:nth-child(even) {
      background: var(--tw-gray-50);
    }
    .line:not(.active):hover {
      background: var(--tw-cyan-50);
      cursor: pointer;
    }
    .line.active {
      background: var(--tw-cyan-200);
    }
    .no-results {
      background: #ffffff;
    }
    .legend-container {
      position: relative;
      display: inline-block;
    }
    .legend {
      position: absolute;
      background: #ffffff;
      width: 290px;
      top: 125%;
      right: -12px;
      visibility: hidden;
    }
    .legend-container:hover .legend {
      visibility: visible;
    }
  }

  .font-color-grey {
    color: #6b7280;
  }
</style>
