<template>
  <div
    v-for="(error, index) in errors"
    :key="error.uid"
  >
    <span
      :id="'error-' + index"
      class="invalid-feedback d-block"
    >{{ error.$message }}</span>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type:     Array,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
</style>
