export default {
  data () {
    return {
      $_debounce_timer: null,
    }
  },
  methods: {
    // Executes the supplied `callback` after `wait` milliseconds using a timeout. If debounce is called again within
    // that period of time the timeout is reset. The purpose is to prevent the callback to execute too frequently.
    debounce (callback, wait) {
      return (...args) => {
        clearTimeout(this.$_debounce_timer)
        this.$_debounce_timer = setTimeout(function () { callback.apply(this, args) }, wait)
      }
    },
  },
}
