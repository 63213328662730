<template>
  <div
    class="dropdown text-end"
    data-test="app-navbar-user-menu"
  >
    <a
      href="#"
      class="d-flex flex-row align-items-center link-dark text-decoration-none dropdown-toggle"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <img
        v-if="image_url"
        :src="image_url"
        width="40"
        height="40"
        class="rounded-circle me-2"
      >
      <div class="flex-column me-2 lh-sm">
        <div
          class="fs-15 fw-medium"
          :class="{ 'text-danger': isImpersonating }"
        >
          {{ name }}
        </div>
        <div
          class="fs-14 fw-light"
          data-test="current-location-display"
        >
          {{ location.name }}
        </div>
      </div>
      <i class="bi bi-chevron-down" />
    </a>
    <ul class="dropdown-menu dropdown-menu-end text-small">
      <li class="dropdown-submenu">
        <a
          href="#"
          data-bs-toggle="dropdown"
          class="dropdown-item dropdown-toggle"
        >
          <i class="bi bi-chevron-left" />
          Byt lager
        </a>
        <ul
          class="dropdown-menu"
          data-test="locations-menu"
        >
          <AppNavbarUserMenuLocations />
        </ul>
      </li>
      <li v-if="isAposuite">
        <a
          href="#"
          class="dropdown-item"
          @click="backToDock"
        >
          Till Dock
        </a>
      </li>
      <li v-if="hasFullAccess">
        <a
          href="#"
          class="dropdown-item"
          @click="openSidekiq"
        >
          <i class="bi bi-gear-fill" />
          Sidekiq
        </a>
      </li>
      <li><hr class="dropdown-divider"></li>
      <li>
        <a
          href="#"
          class="dropdown-item"
          @click="signOut"
        >
          <i class="bi bi-door-closed-fill" />
          Logga ut
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import AppNavbarUserMenuLocations from '@/components/AppNavbarUserMenuLocations.vue'

export default {
  components: {
    AppNavbarUserMenuLocations,
  },
  inject:   ['axios'],
  computed: {
    railsEnv () {
      return this.$store.state.settings.rails_env
    },
    location () {
      return this.$store.state.settings.current_user.location
    },
    name () {
      return this.$store.state.settings.current_user.display_name
    },
    image_url () {
      return this.$store.state.settings.current_user.image_url
    },
    hasFullAccess () {
      return this.$store.state.settings.current_user.full_access
    },
    isAposuite () {
      return this.$store.state.settings.dock_url
    },
    isImpersonating () {
      return this.$store.state.settings.current_impersonator !== null
    },
  },
  methods: {
    openSidekiq () {
      window.open('/admin/sidekiq')
    },
    backToDock () {
      window.open(this.$store.state.settings.dock_url)
    },
    signOut () {
      window.location.href = '/sign_out'
    },
  },
}
</script>

<style scoped lang="scss">
  .dropdown-submenu {
    position: relative;
  }

  .dropdown-submenu .dropdown-menu {
    top: 0;
    right: 100%;
    margin-top: -1px;
  }

  li:hover > ul.dropdown-menu {
      display: block;
  }
</style>
