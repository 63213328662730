import { Tooltip } from 'bootstrap'

export const tooltip = {
  mounted: function (el, binding) {
    el.directive = new Tooltip(el, { title: binding.value })
  },
  unmounted: function (el, binding) {
    el.directive.dispose()
  },
}
