<template>
  <div class="d-inline-flex">
    <div
      ref="dot"
      class="dot badge align-top me-2"
      :class="color"
    >
      {{ letter }}
    </div>
  </div>
</template>

<script>
import { Tooltip } from 'bootstrap'

export default {
  props: {
    tooltip: {
      type:    String,
      default: '',
    },
    color: {
      type:    String,
      default: '',
    },
    letter: {
      type:    String,
      default: '',
    },
  },
  setup () {
    return {
      tooltipEl: null,
    }
  },
  mounted () {
    if (this.tooltip) {
      this.tooltipEl = new Tooltip(this.$refs.dot, { title: this.tooltip })
    }
  },
}
</script>

<style lang="scss" scoped>
  .dot {
    box-sizing: border-box;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.8em;
    height: 1.8em;
    font-size: 12px;
    font-weight: 500;
    padding: 0;

    &.purple {
      color: var(--tw-white);
      background: var(--tw-purple-900);
    }
    &.blue {
      color: var(--tw-sky-900);
      background: var(--tw-sky-300);
    }
    &.green {
      color: var(--tw-green-700);
      background: var(--tw-green-200);
    }
    &.red {
      color: var(--tw-red-700);
      background: var(--tw-red-200);
    }
  }
</style>
