<template>
  <div
    class="row list-item pb-1 pt-1"
    data-test="history-list-item"
  >
    <div class="col-3">
      <div class="fw-medium">
        <i class="bi bi-clock me-1 text-tw-slate-600" />
        {{ formatTimestamp(revision.created_at) }}
      </div>
      <div
        class="fst-italic"
        data-test="history-changed-by"
      >
        <i class="bi bi-person me-1 text-tw-slate-600" />

        {{ revision.changed_by }}
      </div>
      <div
        v-if="revision.changed_by_impersonator"
        class="fst-italic"
        data-test="history-changed-by-impersonator"
      >
        Imitatör: {{ revision.changed_by_impersonator }}
      </div>
    </div>
    <div class="col-9">
      <div class="grid">
        <div
          v-for="change in revision.changes"
          :key="change.column"
          class="row change-item py-1"
        >
          <div class="col">
            {{ $t(`revisions.${change.column}`) }}:
          </div>
          <div
            class="col"
            data-test="history-changed-from"
          >
            <span v-if="change.changed_from">
              {{ change.changed_from }}
            </span>
            <span
              v-else
              class="fst-italic text-tw-slate-400"
            >
              tomt
            </span>
          </div>
          <div class="col-1">
            <i class="bi bi-arrow-right" />
          </div>
          <div
            class="col"
            data-test="history-changed-to"
          >
            {{ change.changed_to || '-' }}
          </div>
        </div>

        <div
          v-if="revision.comment"
          class="row my-2"
          data-test="history-comment"
        >
          <span class="fs-13">
            Kommentar:
            <span class="fst-italic">
              {{ revision.comment }}
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formatTimestamp from '@/utils/formatTimestamp.js'

export default {
  props: {
    revision: {
      type:     Object,
      required: true,
    },
  },
  setup () {
    return {
      formatTimestamp,
    }
  },
}
</script>

<style scoped lang="scss">
.list-item:not(:nth-child(2)) {
  border-top: 1px solid var(--tw-slate-300);
}

.list-item:hover {
  background-color: var(--tw-slate-50);
}

.change-item:nth-child(odd) {
  background-color: var(--tw-slate-100);
}
.change-item:nth-child(even) {
  background-color: var(--tw-slate-50);
}
</style>
