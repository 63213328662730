<template>
  <div>
    <div class="p-3 bg-white rounded-3 border shadow-sm">
      <div class="row">
        <div class="col-9">
          <dt>
            <label class="required">Vara</label>
          </dt>
        </div>
        <div class="col-3">
          <dt>
            <label class="required">Kvantitet</label>
          </dt>
        </div>
      </div>

      <div
        v-for="(line, index) in modelValue"
        :key="index"
        class="lines"
      >
        <div
          :id="'row-' + index"
          class="row"
        >
          <div class="col-9 py-3">
            <item-search
              :ref="`item-line-search-${index}`"
              v-model="line.item"
            />
          </div>
          <div class="col-2 py-3">
            <line-quantity-input
              v-model="line.quantity"
              :step="line.item?.minimum_quantity"
              @enter="add()"
            />
          </div>
          <div class="col-1 py-3 remove">
            <a
              class="btn"
              @click="remove(index)"
            >
              <i class="bi-x-lg" />
            </a>
          </div>
        </div>
      </div>

      <div class="row text-center">
        <a
          href="#"
          class="btn"
          data-test="add-item-button"
          @click.prevent="add()"
        >
          <i class="bi-plus-lg me-1" />
          Lägg till vara
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ItemSearch from '@/components/ItemSearch.vue'
import LineQuantityInput from '@/components/LineQuantityInput.vue'
import useVuelidate from '@vuelidate/core'

export default {
  components: {
    ItemSearch,
    LineQuantityInput,
  },
  props: {
    modelValue: {
      type:     Array,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  setup () {
    return { v$: useVuelidate() }
  },
  methods: {
    add () {
      const newModelValue = this.modelValue.slice()
      const newLineIndex = newModelValue.push({ item: null, quantity: null })

      this.$emit('update:modelValue', newModelValue)

      this.$nextTick(() => {
        this.focusLine(newLineIndex - 1)
      })
    },
    focusLine (index) {
      const refs = this.$refs['item-line-search-' + index]
      if (refs) {
        const itemSearchEl = refs[0]
        if (itemSearchEl && Object.prototype.hasOwnProperty.call(itemSearchEl, 'focusInput')) {
          itemSearchEl.focusInput()
        }
      }
    },
    remove (index) {
      const newModelValue = this.modelValue.slice()
      newModelValue.splice(index, 1)

      this.$emit('update:modelValue', newModelValue)
    },
  },
}
</script>

<style scoped lang="scss">
  .remove {
    padding: 0;
  }
</style>
