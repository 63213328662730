<template>
  <li
    v-for="location in locations"
    :key="location.id"
    class="d-flex"
    data-test="locations-menu-option"
  >
    <a
      href="#"
      class="dropdown-item"
      :class="{'fw-semibold': isSelected(location.id)}"
      @click.prevent="selectLocation(location.id)"
    >
      {{ location.name }}
    </a>
  </li>
</template>

<script>
export default {
  inject: ['axios'],
  data () {
    return {
      locations: [],
    }
  },
  mounted () {
    this.getLocations()
  },
  methods: {
    getLocations () {
      this.axios.get('/internal_api/warehouses')
        .then((response) => {
          this.locations = response.data
        })
    },
    getSettings () {
      this.axios.get('/internal_api/settings')
        .then((response) => {
          this.$store.commit('setSettings', response.data)
        })
    },
    isSelected (id) {
      return this.$store.state.settings.current_user.location.id === id
    },
    selectLocation (id) {
      this.axios.put('/internal_api/settings', { settings: { location_id: id } })
        .then((response) => {
          this.getSettings()
        }).catch(() => {
          this.$flashMessage.show({
            type: 'error',
            text: 'Vi kunde inte byta lager. Vänligen försök igen.',
          })
        })
    },
  },
}
</script>

<style scoped lang="scss">
</style>
