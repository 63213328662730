<template>
  <div class="list-body">
    <div
      v-if="revisions.length"
      class="list-group grid p-3"
    >
      <div class="row fw-medium fs-13 text-tw-slate-600">
        <div class="col offset-3">
          <div class="row">
            <div class="col">
              Fält
            </div>
            <div class="col">
              Från
            </div>
            <div class="col offset-1">
              Till
            </div>
          </div>
        </div>
      </div>
      <HistoryListItem
        v-for="(revision, index) in revisions"
        :key="index"
        :revision="revision"
      />
    </div>
    <div
      v-else
      class="text-center fw-semibold p-5 fs-16 text-tw-slate-500"
    >
      Ingen historik hittades
    </div>
  </div>
</template>

<script>
import HistoryListItem from '@/components/HistoryListItem.vue'
import { inject } from 'vue'

export default {
  components: { HistoryListItem },
  props:      {
    url: {
      type:     String,
      required: true,
    },
  },
  async setup (props) {
    const axios = inject('axios')
    const { data: { revisions } } = await axios.get(props.url)

    return {
      revisions,
    }
  },
}
</script>

<style scoped lang="scss">
.list-body {
  overflow-y: auto;
  max-height: 70vh;
}
</style>
