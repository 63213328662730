<template>
  <tr
    class="order"
    data-test="order"
    @click="openSalesOrder(order.id)"
  >
    <td>
      {{ formatTimestamp(order.ordered_at) }}
    </td>

    <td>
      <div
        class="font-monospace"
        data-test="index-row-order-number"
      >
        {{ order.order_number }}
      </div>
    </td>
    <td>
      <div class="font-monospace">
        {{ order.customer_order_number }}
      </div>
    </td>

    <td>
      <div class="grid">
        <div class="row fs-15">
          <div class="col fw-medium">
            {{ order.customer.name }} (<span class="font-monospace fs-14">{{ order.customer.number }}</span>)
          </div>
        </div>
        <div class="row">
          <div class="col-8 fst-italic">
            {{ order.buyer_name || '-' }}
          </div>
          <div class="col-4">
            <span v-if="useAgreements">
              {{ order.agreement?.name || '-' }}
            </span>
            <span v-else>
              {{ order.region?.name || '-' }}
            </span>
          </div>
        </div>
      </div>
    </td>

    <td>
      {{ order.loading_dock?.name || '-' }}
    </td>

    <td>
      {{ order.shipping_method.name || '-' }}
    </td>
  </tr>
</template>

<script>
import formatTimestamp from '@/utils/formatTimestamp.js'

export default {
  props: {
    order: {
      type:     Object,
      required: true,
    },
  },
  setup () {
    return {
      formatTimestamp,
    }
  },
  computed: {
    useAgreements () {
      return this.$store.state.settings.use_agreements
    },
  },
  methods: {
    openSalesOrder (id) {
      this.$router.push({ name: 'showSalesOrder', params: { id } })
    },
  },
}
</script>

<style lang="scss" scoped>
.order {
  cursor: pointer;
  color: var(--tw-slate-700);
}
</style>
