
import dateFormat from 'dateformat'

const formatTimestamp = (timestamp) => {
  if (timestamp) {
    return dateFormat(timestamp, 'yyyy-mm-dd HH:MM')
  } else {
    return '-'
  }
}
export default formatTimestamp
