import { createStore } from 'vuex'

const store = createStore({
  state: {
    settings: {},
  },
  mutations: {
    setSettings (state, settings) {
      state.settings = settings.app_settings
    },
  },
})

export default store
