<template>
  <div
    v-if="priority"
    class="fw-medium ms-1 circle text-center"
  >
    {{ priority }}
  </div>
</template>

<script>
export default {
  props: {
    priority: {
      type:     Number,
      default:  null,
      required: false,
    },
  },
}
</script>

<style lang="scss" scoped>
  .circle {
    background-color: lightgray;
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 50%;
    display: inline-block;
  }
</style>
