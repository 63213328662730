<template>
  <div class="container">
    <div class="grid p-2">
      <div class="row mb-3">
        <textarea
          v-model="comment"
          rows="4"
          class="form-control"
          data-test="comment"
          placeholder="Kommentar..."
          :class="{'is-invalid': v$.comment.$error}"
        />
      </div>
      <div class="row">
        <button
          class="btn btn-outline-dark"
          @click="$emit('cancel')"
        >
          Avbryt
        </button>
        <LoadingButton
          data-test="close-button"
          class="btn btn-danger ms-auto"
          :loading="loading"
          @click="close"
        >
          <template #perform>
            Stäng order
          </template>
          <template #loading>
            Stänger order...
          </template>
        </LoadingButton>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingButton from '@/components/LoadingButton.vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@/utils/i18n-validators'

export default {
  components: {
    LoadingButton,
  },
  inject: ['axios'],
  props:  {
    orderId: {
      type:     Number,
      required: true,
    },
  },
  emits: ['closeCompleted', 'closeRequestStarted', 'closeRequestDone', 'cancel'],
  setup () {
    return {
      v$: useVuelidate(),
    }
  },
  data () {
    return {
      loading: false,
      comment: '',
    }
  },
  methods: {
    async close () {
      this.v$.$reset()
      const isFormValid = await this.v$.$validate()
      if (!isFormValid) return

      this.$emit('closeRequestStarted')
      this.loading = true
      this.axios.post(`/internal_api/sales_orders/${this.orderId}/close`, { comment: this.comment })
        .then(() => {
          this.$emit('closeCompleted')
          this.$flashMessage.show({
            type: 'success',
            text: 'Ordern har stängts',
          })
        }).catch(() => {
          this.$flashMessage.show({
            type: 'error',
            text: 'Ordern kunde inte stängas. Vänligen försök igen.',
          })
        }).then(() => {
          this.$emit('closeRequestDone')
          this.loading = false
        })
    },
  },
  validations () {
    return {
      comment: {
        required,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
  button {
    width: 10rem;
  }
</style>
