<template>
  <div>
    <select
      class="form-select"
      :class="{'is-invalid': v$.modelValue.$error}"
      data-test="shipping-method-select"
      @change="selectShippingMethod"
    >
      <option
        style="display:none"
        value=""
        :selected="modelValue === null"
        disabled
      />
      <option
        v-for="method in shippingMethods"
        :key="method.id"
        :value="method.id"
        :selected="method.id === modelValue"
      >
        {{ method.name }}
      </option>
    </select>
    <InputErrorDisplay :errors="v$.modelValue.$errors" />
  </div>
</template>

<script>
import InputErrorDisplay from '@/components/InputErrorDisplay.vue'

import useVuelidate from '@vuelidate/core'
import { required } from '@/utils/i18n-validators'

export default {
  components: {
    InputErrorDisplay,
  },
  inject: ['axios'],
  props:  {
    modelValue: {
      type:    Number,
      default: undefined,
    },
    customer: {
      type:    Object,
      default: undefined,
    },
  },
  emits: ['update:modelValue'],
  setup () {
    return { v$: useVuelidate() }
  },
  validations () {
    return {
      modelValue: {
        required,
      },
    }
  },
  data () {
    return {
      shippingMethods: [],
    }
  },
  watch: {
    customer () {
      this.getShippingMethods()
    },
  },
  mounted () {
    this.getShippingMethods()
  },
  methods: {
    selectShippingMethod (event) {
      this.$emit('update:modelValue', parseInt(event.target.value))
    },
    getShippingMethods () {
      if (this.customer) {
        this.axios.get('/internal_api/shipping_methods/options_for_customer?customer_id=' + this.customer.id)
          .then((response) => {
            this.shippingMethods = response.data.available_shipping_methods
            let selectedShippingMethod

            if (this.modelValue !== undefined) {
              selectedShippingMethod = this.modelValue
            } else {
              selectedShippingMethod = response.data.default_shipping_method?.id
            }

            this.$emit('update:modelValue', selectedShippingMethod)
          })
      } else {
        this.shippingMethods = []
        this.$emit('update:modelValue', null)
      }
    },
  },
}
</script>

<style scoped lang="scss">
</style>
