<template>
  <div
    class="badge rounded-pill d-inline-flex align-items-center py-1 ps-2 me-1"
    :class="'temp-' + temperatureClass"
  >
    <i
      class="icon bi d-flex"
      :class="icon"
    />
    <div class="d-flex mx-1">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    temperatureClass: {
      type:     String,
      required: true,
    },
  },
  computed: {
    icon () {
      switch (this.temperatureClass) {
        case 'normal':
          return 'bi-thermometer-high'
        case 'cooler':
          return 'bi-thermometer-low'
        case 'freezer':
          return 'bi-thermometer-snow'
        case 'cryogenic':
          return 'bi-snow'
        default:
          return ''
      }
    },
    title () {
      switch (this.temperatureClass) {
        case 'normal':
          return 'Normal'
        case 'cooler':
          return 'Kyl'
        case 'freezer':
          return 'Frys'
        case 'cryogenic':
          return 'Kryofrys'
        default:
          return '-'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.icon {
  margin-top: .03rem;
}

.temp-normal {
  color: #15803d;
  background: #bbf7d0;
}
.temp-cooler {
  color: #075985;
  background: #bae6fd;
}
.temp-freezer {
  color: #fff;
  background: #0369A1;
}
.temp-cryogenic {
  color: #fff;
  background: #581c87;
}
</style>
