<template>
  <button
    data-test="close-button"
    class="btn btn-danger"
    :disabled="disabled"
    @click="showModal = true"
  >
    Stäng
  </button>

  <VModal
    :open="showModal"
    size="sm"
    title="Är du säker på att du vill stänga ordern?"
    @close="handleModalClose"
  >
    <SalesOrderCloseForm
      data-test="sales-order-close-form"
      :order-id="orderId"
      @close-completed="handleCloseCompleted"
      @close-request-started="lockModal = true"
      @close-request-done="lockModal = false"
      @cancel="handleModalClose"
    />
  </VModal>
</template>

<script>
import VModal from '@/components/VModal.vue'
import SalesOrderCloseForm from '@/components/SalesOrderCloseForm.vue'

export default {
  components: {
    VModal,
    SalesOrderCloseForm,
  },
  props: {
    disabled: {
      type:     Boolean,
      required: false,
      default:  false,
    },
    orderId: {
      type:     Number,
      required: true,
    },
  },
  emits: ['closeCompleted'],
  data () {
    return {
      showModal: false,
      lockModal: false,
    }
  },
  methods: {
    handleModalClose () {
      if (this.lockModal === true) return

      this.showModal = false
    },
    handleCloseCompleted () {
      this.$emit('closeCompleted')
      this.showModal = false
    },
  },
}
</script>

<style lang="scss" scoped>
  button {
    width: 13em;
  }
</style>
