<template>
  <div class="col-1">
    &nbsp;
  </div>

  <div class="col-5 pb-2">
    <div class="fs-13 d-flex overflow-hidden">
      <div class="font-monospace me-1 fw-semibold">
        {{ item.vendor_name }}
      </div>
      <div class="fw-medium">
        {{ item.name }}
      </div>
      <ItemPriority :priority="item.priority" />
    </div>
    <div class="fs-11 row">
      <div class="col-4">
        {{ item.form }}
      </div>
      <div class="col-2 ms-3">
        {{ item.strength_text }}
      </div>
      <div class="col-2">
        {{ item.amount_text }}
      </div>
      <div class="col-3">
        {{ item.inner_package_type }}
      </div>
    </div>
    <div class="d-flex flex-wrap">
      <ItemBadges :item="item" />
    </div>
  </div>

  <div class="col-3">
    <div class="row">
      <div class="col-5">
        <div class="quantity-available fs-16 fw-medium col-7">
          {{ item.quantity_available }}
          <LetterDot
            v-if="item['keep_in_stock?']"
            color="blue"
            letter="L"
            tooltip="Lagerförd vara"
            class="align-top"
          />
        </div>
        <div
          v-if="renderBackupStorageInventory"
          class="col-5"
        >
          <span>{{ item.backup_storage_surplus_balance }} / {{ item.backup_storage_available_balance }}</span>
        </div>
      </div>
      <div class="fs-11 col-5">
        <div class="row">
          <div class="col">
            {{ vendor(item) }}
          </div>
        </div>
        <div
          v-if="item.preferred_vendor"
          class="row"
        >
          <div class="col">
            {{ $t(`wholesaleStockStatus.${item.wholesale_stock_status}`) }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="fs-16 fw-medium col-3 text-end">
    <div class="col-1">
      {{ currencySEK(item.price) }}
    </div>
    <div class="col text-start">
      <ItemLetterDots
        :is-missing-license="item.article_type === 'non_licensed_drug' && !item['valid_license?']"
        :has-contracted-price="item['contracted_price?']"
        :backup-storage-surplus="item['backup_storage_surplus?']"
        :item-is-closing-sale="item['closing_sale?']"
        :special-handling-instructions="item.special_handling_instructions"
      />
    </div>
  </div>
</template>

<script>
import currencySEK from '@/composables/currencySEK.js'
import ItemLetterDots from '@/components/ItemLetterDots.vue'
import ItemBadges from '@/components/ItemBadges.vue'
import ItemPriority from '@/components/ItemPriority.vue'
import LetterDot from '@/components/LetterDot.vue'

export default {
  components: {
    ItemLetterDots,
    ItemBadges,
    ItemPriority,
    LetterDot,
  },
  props: {
    item: {
      type:    Object,
      default: () => {},
    },
  },
  setup (props, { emit }) {
    const vendor = (item) => {
      if (item.preferred_vendor) {
        return item.preferred_vendor.name
      } else {
        return '-'
      }
    }
    return {
      currencySEK,
      vendor,
    }
  },
  computed: {
    renderBackupStorageInventory () {
      return this.item.backup_storage_surplus_balance !== 0 || this.item.backup_storage_available_balance !== 0
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
