<template>
  <button
    :disabled="loading"
  >
    <div v-if="loading">
      <div class="text-center">
        <span
          class="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"
        />
        <span class="sr-only">
          <slot
            name="loading"
          >
            Loading...
          </slot>
        </span>
      </div>
    </div>
    <div v-else>
      <slot
        name="perform"
      >
        Perform
      </slot>
    </div>
  </button>
</template>

<script>

export default {
  props: {
    loading: {
      type:     Boolean,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
</style>
