import { createI18n } from 'vue-i18n'

const messages = {
  sv: {
    validations: {
      required:              'Måste vara ifyllt',
      integer:               'Måste vara ett heltal',
      minValue:              'Får inte vara mindre än {min}',
      maxValue:              'Får inte vara högre än {max}',
      maxLength:             'Får inte vara längre än {max} tecken',
      multipleOf:            'Måste vara en multipel av {num}',
      multipleOfMinQuantity: 'Kvantiteten måste vara en multipel av varans minimumkvantitet ({num})',
    },
    revisions: {
      item:                 'Vara',
      quantity:             'Antal',
      netsuite_internal_id: 'Netsuite internal id',
      order_number:         'Ordernummer',
      state:                'Status',
      exchange_comment:     'Kommentar till utbyte',
      exchange_reason:      'Anledning till utbyte',
    },
    salesOrder: {
      numberOfLines: '0 orderrader | 1 orderrad | {count} orderrader',
      state:         {
        new:            'Ny',
        export_failed:  'Export misslyckades',
        exporting:      'Exporterar',
        exported:       'Exporterad',
        approve_failed: 'Godkännande misslyckades',
        approving:      'Godkänner',
        approved:       'Godkänd',
        closed:         'Stängd',
      },
    },
    exchangeReasons: {
      due_to_backorder:         'Restsituation (pga)',
      to_item_in_stock:         'Lagerförd vara (till)',
      to_pricing_contract_item: 'Upphandlad vara (till)',
      to_equivilant_item:       'Synonym vara (till)',
      according_to_deal:        'Enligt ök',
      after_contact:            'Efter kontakt med avd',
    },
    wholesaleStockStatus: {
      in_stock:      'I lager',
      limited_stock: 'Begränsat lager',
      not_in_stock:  'Ej i lager',
      null:          'Okänd lagerstatus',
    },
    validationSummary: {
      totalBlocks:   '{count} fel som hindrar godkännande',
      totalWarnings: '0 varningar | 1 varning | {count} varningar',
    },
  },
}

const i18n = createI18n({
  locale: 'sv',
  messages,
})

export default i18n
