<template>
  <button
    class="btn btn-outline-dark"
    :disabled="newOrders.count === 0"
    @click="click"
  >
    {{ newOrders.count }} inkomna säljordrar
  </button>
</template>

<script>
import { reactive, watch } from 'vue'
import { useActionCable } from '@/composables/useActionCable.js'

export default {
  props: {
    locationId: {
      type:     Number,
      required: true,
    },
  },
  emits: ['fetch-sales-orders'],
  setup (props) {
    const { cableCreateSubscription } = useActionCable()
    const newOrders = reactive({ count: 0 })

    let subscription = null

    const subscribe = (locationId) => {
      if (subscription) {
        subscription.unsubscribe()
      }

      subscription = cableCreateSubscription(
        {
          channel:     'NewSalesOrderChannel',
          location_id: locationId,
        },
        {
          received (data) {
            const newCount = (newOrders.count + data.body)
            watch(() => {
              newOrders.count = newCount
            })
          },
        },
      )
    }

    subscribe(props.locationId)

    watch(() => props.locationId, (newLocationId) => {
      subscribe(newLocationId)
    })

    return {
      newOrders,
    }
  },
  methods: {
    click () {
      this.$emit('fetch-sales-orders')
      this.newOrders.count = 0
    },
  },
}
</script>

<style scoped lang="scss">
</style>
