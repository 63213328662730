<template>
  <div class="grid">
    <div class="row">
      <div class="col">
        <textarea
          v-model="comment"
          rows="4"
          class="form-control text-area"
          data-test="comment-textarea"
          placeholder="Kommentar..."
        />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <button
          class="btn btn-success w-100 vertical-align-baseline"
          data-test="confirm-update-comment-button"
          @click.prevent="confirm()"
        >
          Bekräfta
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  inject: ['axios'],
  props:  {
    salesOrderId: {
      type:     Number,
      required: true,
    },
    initialComment: {
      type:     String,
      required: true,
    },
  },
  emits: ['confirm', 'close'],
  setup (props) {
    return { comment: props.initialComment }
  },
  watch: {
    initialComment () {
      this.comment = this.initialComment
    },
  },
  methods: {
    async confirm () {
      this.axios.put(
        '/internal_api/sales_orders/' + this.salesOrderId,
        { comment: this.comment },
      ).then((response) => {
        this.$emit('confirm', { comment: response.data.data.comment })
        this.$emit('close')
      }).catch((error) => {
        const message = error.response.data?.message?.errors?.join(', ') || 'Något gick fel. Försök igen senare.'
        this.$flashMessage.show({
          type: 'error',
          text: message,
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.text-area {
  width: 25rem;
}
</style>
