<template>
  <tr
    class="order"
    :class="{express: isExpress}"
    data-test="order"
    @click="openSalesOrder(order.id)"
  >
    <td class="ps-3">
      <div class="fw-bold font-monospace fs-14">
        {{ dateFormat(order.ordered_at, 'HH:MM') }}
      </div>
      <div class="fw-medium fs-13">
        {{ dateFormat(order.ordered_at, 'ddd, d mmm') }}
      </div>
    </td>

    <td>
      <div class="mb-1 font-monospace fs-13 fw-medium text-tw-slate-900 text-break">
        {{ order.customer_order_number }}
      </div>
      <div
        class="font-monospace fs-12"
        data-test="index-row-order-number"
      >
        {{ order.order_number }}
      </div>
    </td>

    <td>
      <div class="grid">
        <div class="row fs-14">
          <div class="col fw-medium text-tw-slate-900">
            <span class="border-bottom">
              {{ order.customer.name }} (<span class="font-monospace fs-13">{{ order.customer.number }}</span>)
            </span>
          </div>
        </div>
        <div class="row fs-13">
          <div class="col-8 fst-italic">
            {{ order.buyer_name || '-' }}
          </div>
          <div class="col-4">
            <span v-if="useAgreements">
              {{ order.agreement?.name || '-' }}
            </span>
            <span v-else>
              {{ order.region?.name || '-' }}
            </span>
          </div>
        </div>
      </div>
    </td>

    <td>
      <div class="fs-13">
        {{ order.loading_dock?.name || '-' }}
      </div>
    </td>

    <td>
      <div class="fs-13">
        {{ order.shipping_method.name || '-' }}
      </div>
    </td>

    <td>
      <div class="fs-12">
        {{ $t('salesOrder.numberOfLines', {count: order.line_count}) }}
      </div>
      <div class="fs-12">
        {{ currencySEK(order.total_price) }}
      </div>
      <div class="d-flex flex-column mt-1">
        <VBadge
          v-if="isExpress"
          color="red"
          class="align-self-baseline"
        >
          Prio
        </VBadge>
        <VBadge
          v-if="order.license_issue"
          color="pink"
          class="align-self-baseline"
        >
          Licens saknas
        </VBadge>
        <VBadge
          v-if="!order.allow_item_substitution"
          color="dark-gray"
          class="align-self-baseline"
        >
          Ej Utbyte
        </VBadge>
      </div>
    </td>
    <td>
      <div class="fs-12">
        <VBadge
          v-if="order.locked_by"
          icon="bi-lock"
          :color="isPreparedByCurrentUserColor"
        >
          Bereds av {{ order.locked_by.display_name }}
        </VBadge>
      </div>
    </td>
  </tr>
</template>

<script>
import { ref } from 'vue'
import VBadge from '@/components/VBadge.vue'
import dateFormat from 'dateformat'
import currencySEK from '@/composables/currencySEK.js'

export default {
  components: {
    VBadge,
  },
  props: {
    salesOrder: {
      type:     Object,
      required: true,
    },
  },
  setup (props) {
    const order = ref(props.salesOrder)

    return {
      currencySEK,
      dateFormat,
      order,
    }
  },
  computed: {
    isExpress () {
      return this.order.is_express
    },
    isPreparedByCurrentUserColor () {
      if (this.order.locked_by.display_name === this.$store.state.settings.current_user.display_name) {
        return 'dark-blue'
      } else {
        return ''
      }
    },
    useAgreements () {
      return this.$store.state.settings.use_agreements
    },
  },
  watch: {
    salesOrder: function (updatedOrder) {
      this.order = updatedOrder
    },
  },
  methods: {
    openSalesOrder (id) {
      this.$router.push({ name: 'showSalesOrder', params: { id } })
    },
  },
}
</script>

<style lang="scss" scoped>
.order {
  cursor: pointer;
  color: var(--tw-slate-700);

  &.express {
    box-shadow: 3px 0 0 #dc2626 inset;
  }
}
</style>
