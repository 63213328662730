<template>
  <div
    class="badge rounded-pill d-inline-flex align-items-center ps-2 py-1 me-1 mt-1"
    :class="[color, outline ? 'outline' : '']"
  >
    <i
      v-if="icon"
      class="icon bi d-flex"
      :class="icon"
    />
    <div class="d-flex mx-1">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type:    String,
      default: '',
    },
    color: {
      type:    String,
      default: '',
    },
    outline: {
      type:    Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.icon {
  margin-top: .03rem;
}

.red {
  color: #fef2f2;
  background: #dc2626;
}
.green {
  color: #15803d;
  background: #bbf7d0;
}
.yellow {
  color: #92400e;
  background: #fde68a;
}
.light-blue {
  color: #075985;
  background: #bae6fd;
}

.dark-blue {
  color: #fff;
  background: #0369A1;

  &.outline {
    color: #0369A1;
    border: 1px solid #0369A1;
    background: #fff;
  }
}

.pink {
  color: #831843;
  background: #fbcfe8;
}

.dark-gray {
  color: #f5f5f4;
  background: #78716c;
}
</style>
