<template>
  <div data-test="item-search">
    <VAutocomplete
      ref="autocomplete"
      placeholder="Sök efter Varu-nr, Varu-namn, Storlek"
      :value="modelValue"
      :options="items"
      :has-validation-error="v$.modelValue.$error"
      @query-changed="findItems"
      @option-selected="emitSelection"
    >
      <template #list-item="{item, query}">
        <!-- eslint-disable vue/no-v-html -->
        <div class="fs-15 fw-bold">
          <span v-html="highlightSearchResult(item.vendor_name, query, false)" /> -
          <span v-html="highlightSearchResult(item.name, query, false)" />
        </div>
        <div class="row fs-14">
          <div class="col">
            <em>Storlek: </em>
            <span v-html="highlightSearchResult(item.amount_text, query, false)" />
          </div>
          <div class="col">
            <em>Styrka: </em>
            <span v-html="highlightSearchResult(item.strength_text, query, false)" />
          </div>
        </div>
        <!-- eslint-enable vue/no-v-html -->
      </template>

      <template #selected-item="{item}">
        <SimpleItemInfo :item="item" />
      </template>
    </VAutocomplete>
    <InputErrorDisplay :errors="v$.modelValue.$errors" />
  </div>
</template>

<script>
import VAutocomplete from './VAutocomplete.vue'
import InputErrorDisplay from '@/components/InputErrorDisplay.vue'
import highlightSearchResult from '@/components/mixins/highlightSearchResult.js'
import SimpleItemInfo from '@/components/SimpleItemInfo.vue'

import useVuelidate from '@vuelidate/core'
import { required } from '@/utils/i18n-validators'

export default {
  components: {
    VAutocomplete,
    InputErrorDisplay,
    SimpleItemInfo,
  },
  mixins: [highlightSearchResult],
  inject: ['axios'],
  props:  {
    modelValue: {
      default: () => {},
      type:    Object,
    },
    validatorId: {
      type:    String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup () {
    return { v$: useVuelidate() }
  },
  validations () {
    return {
      modelValue: {
        required,
      },
    }
  },
  data () {
    return {
      items: [],
    }
  },
  methods: {
    findItems (query) {
      this.axios.get('/internal_api/items?filter=' + query)
        .then((response) => {
          this.items = response.data.items
        })
    },
    emitSelection (newValue) {
      this.$emit('update:modelValue', newValue)
    },
    focusInput () {
      this.$refs.autocomplete.focusInput()
    },
  },
}
</script>

<style scoped lang="scss">
</style>
