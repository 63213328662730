<template>
  <button
    v-if="lockedBy === null"
    data-test="lock-button"
    class="btn btn-primary"
    @click="lock"
  >
    <i class="bi bi-unlock" />
    Bered order
  </button>

  <button
    v-if="lockedBy !== null"
    data-test="lock-button"
    class="btn btn-danger text-nowrap"
    @click="lock"
  >
    <i class="bi bi-lock" />
    Bereds av {{ lockedBy.display_name }}
  </button>
</template>

<script>
export default {
  inject: ['axios'],
  props:  {
    lockedBy: {
      type:    Object,
      default: () => {},
    },
    orderId: {
      type:     Number,
      required: true,
    },
  },
  emits:   ['update:locked-by'],
  methods: {
    lock () {
      this.axios.patch(`/internal_api/sales_orders/${this.orderId}/lock`)
        .then((response) => {
          this.$emit('update:locked-by', response.data.data.locked_by)
        }).catch(() => {
          this.$flashMessage.show({
            type: 'error',
            text: 'Vi kunde inte låsa ordern. Vänligen försök igen.',
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
