<template>
  <div>
    <input
      type="number"
      min="0"
      max="99999"
      :value="modelValue"
      :step="step"
      class="form-control"
      :class="{'is-invalid': v$.modelValue.$error}"
      data-test="line-quantity-input"
      @input="$emit('update:modelValue', $event.target.value)"
      @keydown.enter.prevent="$emit('enter')"
    >
    <InputErrorDisplay :errors="v$.modelValue.$errors" />
  </div>
</template>

<script>
import InputErrorDisplay from '@/components/InputErrorDisplay.vue'

import useVuelidate from '@vuelidate/core'
import { required, minValue, maxValue, integer, multipleOf } from '@/utils/i18n-validators'
import { helpers } from '@vuelidate/validators'

export default {
  components: {
    InputErrorDisplay,
  },
  props: {
    modelValue: {
      default: '',
      type:    String,
    },
    step: {
      default: null,
      type:    Number,
    },
  },
  emits: ['update:modelValue', 'enter'],
  setup () {
    return { v$: useVuelidate() }
  },
  validations () {
    return {
      modelValue: {
        required,
        integer,
        minValue:   minValue(0),
        maxValue:   maxValue(99999),
        multipleOf: helpers.withMessage(({ $params }) =>
          this.$t('validations.multipleOfMinQuantity', { num: $params.num }), multipleOf(this.step)),
      },
    }
  },
}
</script>

<style scoped lang="scss">
</style>
