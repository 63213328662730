import * as validators from '@vuelidate/validators'
import i18n from '@/config/i18n'
import multipleOfValidator from '@/validators/multipleOfValidator.js'

const { createI18nMessage } = validators

const { t } = i18n.global || i18n

const withI18nMessage = createI18nMessage({ t })

export const required = withI18nMessage(validators.required)
export const integer = withI18nMessage(validators.integer)
export const minValue = withI18nMessage(validators.minValue, { withArguments: true })
export const maxValue = withI18nMessage(validators.maxValue, { withArguments: true })
export const maxLength = withI18nMessage(validators.maxLength, { withArguments: true })
export const multipleOf = withI18nMessage(multipleOfValidator, { withArguments: true })
