import { useFetch } from "@/composables/useFetch.ts"

interface Warehouse {
  id: number
  name: string
}

export function useWarehouses() {
  const {
    data: warehouses,
    fetch: fetchWarehouses,
    loading,
  } = useFetch<Warehouse[]>("/internal_api/warehouses", {}, { immediate: true })

  return { warehouses, fetchWarehouses, loading }
}
