<template>
  <div>
    <div class="container">
      <h2 class="mt-4">
        {{ stats.count }} säljordrar att godkänna
      </h2>
    </div>

    <div class="container">
      <div class="d-flex flex-row my-3 gap-2 justify-content-end">
        <NewSalesOrdersButton
          :location-id="locationId"
          @fetch-sales-orders="fetchSalesOrders"
        />

        <router-link
          class="btn btn-secondary text-uppercase text-decoration-none error"
          to="/sales_orders/failed"
        >
          {{ failedOrderText }}
        </router-link>
        <router-link
          class="btn btn-secondary text-uppercase text-decoration-none success"
          to="/sales_orders/approved"
          data-test="show-approved-orders-link"
        >
          Hanterade ordrar
        </router-link>
      </div>

      <Loading
        :active="loading"
        :is-full-page="false"
      />

      <SalesOrdersIndexFilterSelector
        class="mb-4 p-0"
        :selected-filters="$route.query"
        @update:selected-filters="updateFilters"
      />
      <div class="bg-white rounded-3 shadow-sm p-3">
        <table class="table table-hover-custom table-striped-custom">
          <thead>
            <tr class="align-bottom fs-14">
              <th>Beställdes</th>
              <th id="order-number">
                <div>Kunds Order-nr</div>
                <div>Order-nr</div>
              </th>
              <th>
                <div class="grid">
                  <div class="row">
                    <div class="col text-uppercase">
                      Kund
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8">
                    Beställare
                  </div>
                  <div class="col-4">
                    <span v-if="useAgreements">
                      Avtal
                    </span>
                    <span v-else>
                      Region
                    </span>
                  </div>
                </div>
              </th>
              <th>Lastkaj</th>
              <th>Leveransmetod</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <SalesOrdersIndexTableRow
              v-for="order in salesOrders"
              :key="order.id"
              :sales-order="order"
            />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import SalesOrdersIndexTableRow from '@/components/SalesOrdersIndexTableRow.vue'
import NewSalesOrdersButton from '@/components/NewSalesOrdersButton.vue'
import SalesOrdersIndexFilterSelector from '@/components/SalesOrdersIndexFilterSelector.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useActionCable } from '@/composables/useActionCable.js'

export default {
  components: {
    Loading,
    SalesOrdersIndexTableRow,
    NewSalesOrdersButton,
    SalesOrdersIndexFilterSelector,
  },
  inject: ['axios'],
  setup () {
    const { cableCreateSubscription } = useActionCable()
    const salesOrders = ref([])

    const store = useStore()

    let subscription = null

    const subscribe = (locationId) => {
      if (subscription) {
        subscription.unsubscribe()
      }
      subscription = cableCreateSubscription(
        {
          channel:     'UpdatedSalesOrderChannel',
          location_id: locationId,
        },
        {
          received (data) {
            const updatedSalesOrder = data.sales_order
            const index = salesOrders.value.findIndex(order => order.id === updatedSalesOrder.id)

            if (index !== -1) {
              salesOrders.value.splice(index, 1, updatedSalesOrder)
            }
          },
        },
      )
    }

    const locationId = store.state.settings.current_user.location.id
    subscribe(locationId)

    return {
      salesOrders,
      subscribe,
    }
  },
  data () {
    return {
      loading:          false,
      stats:            {},
      failedOrderCount: 0,
    }
  },
  computed: {
    locationId () {
      return this.$store.state.settings.current_user.location.id
    },
    displayedSalesOrderCount () {
      return this.salesOrders.length
    },
    useAgreements () {
      return this.$store.state.settings.use_agreements
    },
    failedOrderText () {
      return this.failedOrderCount > 0 ? `${this.failedOrderCount} Felande ordrar` : 'Felande ordrar'
    },
  },
  watch: {
    $route: {
      handler: function () {
        this.fetchSalesOrders()
      },
    },
    locationId: {
      handler: function (locationId) {
        this.$router.replace({ query: { search: undefined } })
        this.fetchSalesOrders()
        this.subscribe(locationId)
      },
    },
  },
  mounted () {
    this.fetchSalesOrders()
    this.fetchFailedSalesOrders()
  },
  methods: {
    fetchSalesOrders () {
      this.loading = true
      this.axios.get('/internal_api/sales_orders/unapproved', {
        params: {
          location_id:     this.$store.state.settings.current_user.location.id,
          region:          this.$route.query.region,
          agreement:       this.$route.query.agreement,
          loading_dock:    this.$route.query.loadingDock,
          shipping_method: this.$route.query.shippingMethod,
        },
      }).then((response) => {
        const data = response.data
        this.salesOrders = data.sales_orders
        this.stats = data.stats
      }).catch((_response) => {
        this.$flashMessage.show({
          type: 'error',
          text: 'Ett oväntat fel har inträffat. Var vänlig försök igen.',
        })
      }).then(() => {
        this.loading = false
      })
    },
    fetchFailedSalesOrders () {
      this.axios.get('/internal_api/sales_orders/failed', {})
        .then((response) => {
          this.failedOrderCount = response.data.stats.count
        })
    },
    updateFilters (filters) {
      this.$router.replace({ query: filters })
    },
  },
}

</script>

<style scoped>
#order-number {
  width: 12%;
}

.error {
  background-color: #ffe5d0;
}

.success {
  background-color: #d1e7dd;
}
</style>
