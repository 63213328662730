<template>
  <div
    class="text-center mt-2 text-uppercase fw-medium"
    :class="color"
  >
    <i
      :class="icon"
    />
    {{ error.text }}
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type:     Object,
      required: true,
    },
  },
  computed: {
    color () {
      switch (this.error.type) {
        case 'block':
          return 'red'
        case 'lightWarning':
        case 'warning':
          return 'yellow'
        default:
          return ''
      }
    },
    icon () {
      switch (this.error.type) {
        case 'block':
          return 'bi-exclamation-diamond'
        case 'lightWarning':
        case 'warning':
          return 'bi-exclamation-triangle'
        default:
          return 'bi-question'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
div {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;

  i {
    font-size: 2rem;
    display: block;

    &::before {
      padding: 0.5rem;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      margin-bottom: 0.4rem;
    }
  }
}

.yellow {
  color: #92400e;

  i::before {
    background: #FDE68A;
  }
}

.red {
  color: #B91C1C;

  i::before {
    background: #FECACA;
  }
}
</style>
