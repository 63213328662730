<template>
  <div>
    <ul class="pagination">
      <li
        v-for="(page, index) in allPages"
        :key="index"
        class="page-item"
        :class="page.classes"
      >
        <a
          :id="'page-button-' + index"
          class="page-link"
          data-test="page-link"
          @click="selectPage(page.number)"
        >
          {{ page.text }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    pageInfo: {
      type:     Object,
      required: true,
    },
  },
  computed: {
    allPages () {
      const pages = []
      pages.push(this.prevLink())

      this.pageInfo.series.forEach(item => pages.push(this.pageLink(item)))

      pages.push(this.nextLink())

      return pages
    },
  },
  methods: {
    selectPage (page) {
      if (page !== null && this.$route.query.page !== parseInt(page)) {
        this.$router.push({ path: this.$route.path, query: { page: page } })
      }
    },
    prevLink () {
      return {
        text:    '‹ Föregående',
        number:  this.pageInfo.prev,
        classes: {
          disabled: !this.pageInfo.prev,
        },
      }
    },
    pageLink (item) {
      return {
        text:    item === 'gap' ? '...' : item,
        number:  item,
        classes: {
          active:   parseInt(item) === this.pageInfo.page,
          disabled: item === 'gap',
        },
      }
    },
    nextLink () {
      return {
        text:    'Nästa ›',
        number:  this.pageInfo.next,
        classes: {
          disabled: !this.pageInfo.next,
        },
      }
    },
  },
}
</script>

<style scoped lang="scss">
.pagination {
  li {
    cursor: pointer;
  }

  .disabled {
    cursor: auto;
  }
}
</style>
