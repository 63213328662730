<template>
  <Teleport to="body">
    <div
      v-if="open"
    >
      <div
        :class="size"
        class="this-modal modal-dialog"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ title }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-test="modal-close-button"
              @click="close"
            />
          </div>
          <Suspense>
            <slot />
            <template #fallback>
              <div class="d-flex justify-content-center">
                <div
                  class="spinner-border"
                  role="status"
                />
              </div>
            </template>
          </Suspense>
        </div>
      </div>
      <div
        class="overlay"
        @click="close"
      />
    </div>
  </Teleport>
</template>

<script>
export default {
  props: {
    open: {
      type:     Boolean,
      required: true,
    },
    title: {
      type:    String,
      default: '',
    },
    size: {
      type:    String,
      default: 'md',
    },
  },
  emits:   ['close'],
  methods: {
    close () {
      this.$emit('close')
    },
  },
}
</script>

<style scoped lang="scss">
  .this-modal {
    position: fixed;
    z-index: 999;
    top: 10%;
    left: 50%;
    overflow: scroll;
    height: auto;
    display: table;
    transform: translate(-50%, 0%);
    max-width: 100%;
  }

  .overlay {
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(128,128,128,0.5);
  }

  .sm {
    width: 30em;
  }

  .md {
    width: 50em;
  }

  .lg {
    width: 70em;
  }
</style>
