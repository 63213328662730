
import { helpers } from '@vuelidate/validators'

const multipleOf = (num) =>
  helpers.withParams(
    { num: num },
    (value) => num === null || value % num === 0,
  )

export default multipleOf
