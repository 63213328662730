const start = (name) => {
  performance.mark(`${name}_start`)
}

const stop = (name, callback) => {
  try {
    performance.mark(`${name}_stop`)

    const duration = performance.measure(name, `${name}_start`, `${name}_stop`)?.duration

    if (callback && duration) callback(duration)
  } catch (error) {
    console.error(error)
  }
}

export default { start, stop }
