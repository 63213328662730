import { onUnmounted } from 'vue'
import consumer from '@/channels/consumer.js'

export function useActionCable () {
  const subscriptions = {}

  const getSubscription = function (subscribeOptions) {
    const identifier = JSON.stringify(subscribeOptions)
    const subscription = subscriptions[identifier]

    if (subscription === undefined) {
      throw Error(`No subscription with identifier "${identifier}" exists`)
    }

    return subscription
  }

  const removeSubscription = function (subscribeOptions) {
    const identifier = JSON.stringify(subscribeOptions)
    delete subscriptions[identifier]
  }

  const cableCreateSubscription = function (subscribeOptions, handlerOptions) {
    const identifier = JSON.stringify(subscribeOptions)
    const subscription = subscriptions[identifier]

    if (subscription !== undefined) subscription.unsubscribe()

    subscriptions[identifier] = consumer.subscriptions.create(subscribeOptions, handlerOptions)
  }

  const cableUnsubscribe = function (subscribeOptions) {
    getSubscription(subscribeOptions).unsubscribe()
    removeSubscription(subscribeOptions)
  }

  const cablePerform = function (subscribeOptions, action, data) {
    getSubscription(subscribeOptions).perform(action, data)
  }

  onUnmounted(() => {
    Object.entries(subscriptions).forEach(([_key, subscription]) => {
      subscription.unsubscribe()
    })
  })

  return { cableCreateSubscription, cableUnsubscribe, cablePerform }
}
