const formatter = new Intl.NumberFormat('sv-SE', {
  style:    'currency',
  currency: 'SEK',
})

const currencySEK = (value) => {
  if (value == null) {
    return '-'
  } else {
    return formatter.format(value)
  }
}

export default currencySEK
