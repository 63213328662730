<template>
  <div class="grid pb-2">
    <div class="row pt-2">
      <div class="col offset-1 fs-14 fw-medium">
        Är du säker på att du vill genomföra detta utbyte?
      </div>
      <div class="col-1 text-end">
        <button
          v-tooltip="'Avbryt'"
          type="button"
          class="btn-close btn-close-white"
          aria-label="Avbryt"
          @click.prevent.stop="$emit('cancel')"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-3 offset-1">
        <ExchangeReason v-model="reason" />
      </div>

      <div class="col-1">
        <VDefinition
          label="Kvantitet"
          required
        >
          <line-quantity-input
            v-model="newQuantity"
            :step="minimumQuantity"
          />
        </VDefinition>
      </div>

      <div class="col-4">
        <VDefinition label="Kommentar">
          <textarea
            v-model="newComment"
            rows="1"
            class="form-control"
            data-test="comment"
          />
        </VDefinition>
      </div>

      <div class="col-2">
        <VDefinition label="&nbsp;">
          <button
            href="#"
            class="btn btn-success w-100 vertical-align-baseline"
            data-test="confirm-substitution"
            @click.prevent="confirm()"
          >
            Bekräfta
          </button>
        </VDefinition>
      </div>
    </div>
  </div>
</template>

<script>
import VDefinition from '@/components/VDefinition.vue'
import LineQuantityInput from '@/components/LineQuantityInput.vue'
import ExchangeReason from '@/components/ExchangeReason.vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@/utils/i18n-validators'
import { ref } from 'vue'

export default {
  components: {
    VDefinition,
    LineQuantityInput,
    ExchangeReason,
  },
  props: {
    comment: {
      type:    String,
      default: '',
    },
    minimumQuantity: {
      type:    Number,
      default: null,
    },
    quantity: {
      type:     Number,
      required: true,
    },
  },
  emits: ['cancel', 'confirm'],
  setup (props) {
    return {
      exchangeReasons: ref([]),
      reason:          ref(null),
      newComment:      ref(props.comment),
      newQuantity:     ref(props.quantity.toString()),
      v$:              useVuelidate(),
    }
  },
  created () {
    this.exchangeReasons = this.$store.state.settings.exchange_reasons
  },
  methods: {
    async confirm () {
      this.v$.$reset()
      const isFormValid = await this.v$.$validate()
      if (!isFormValid) return

      this.$emit('confirm', {
        reason:   this.reason,
        comment:  this.newComment,
        quantity: this.newQuantity || `${this.quantity}`,
      })
    },
  },
  validations () {
    return {
      reason: {
        required,
      },
    }
  },
}
</script>

<style lang="css" scoped>
  .grid {
    background: #cffafe;
  }

  .btn-close {
    padding: 0.4em;
    background-size: 10px;
    border-radius: 50%;
    background-color: #ccc;
  }
</style>
